import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import GenericService from '@apiService/GenericService';
import { fetchPages } from '@store/ancilliary/ancilliaryActions';
import { getLangSpecificAttribute } from '@helper/languages';

const FAQ = ({ t }) => {
  const [pageContent, setPageContent] = useState(null);
  const faq = useRef();
  const dispatch = useDispatch();

  const selected_language = useSelector((state) => state.movies.selected_language);

  const PageHtmlData = useSelector((state) => state.ancilliary.ms_page);
  const pageLoading = useSelector((state) => state.ancilliary.pageLoading);

  const { iso_2, lang_id, lang_name } = selected_language;

  const returnLoader = () => {
    if (pageLoading) {
      return `<div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>`;
    } else {
      return t('No Content Available');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchPages(4));
  }, []);

  useEffect(() => {
    let noDataHtml =
      `<div class="" style="min-height: 35vh; line-height: 35vh;"><div class="row"><div class="col-12 text-center"><div>` +
      returnLoader() +
      `</div></div></div></div>`;
    let htmlData = getLangSpecificAttribute(
      PageHtmlData,
      lang_id,
      'page_content',
      'lang_id',
    );
    if (htmlData) {
      faq.current.innerHTML = htmlData;
    } else {
      faq.current.innerHTML = noDataHtml;
    }
    // faq.current.innerHTML = htmlData;
  }, [lang_id, PageHtmlData]);

  return (
    <div className="container-fluid ms-page header-space" ref={faq}>
      <div className="dsdss"></div>
    </div>
  );
};
export default withNamespaces()(FAQ);
