import React, { useState } from 'react';
import { Row, Col, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { selectedTheme } from '../../store/movies/movieActions';

const ThemeSelect = () => {
  const themeDetails = useSelector((state) => state.movies.organizationTheme);
  const dispatch = useDispatch();
  const [themeValue, setThemeValue] = useState();
  const [themes, setThemes] = useState([
    { theme_name: 'Dark Theme', theme_value: 0 },
    { theme_name: 'Red Theme', theme_value: 1 },
    { theme_name: 'Blue Theme', theme_value: 2 },
  ]);
  const handleChange = (e) => {
    console.log(themeDetails, '....valuessss');
    setThemeValue(e.target.value);
    dispatch(selectedTheme(e.target.value));
    console.log(e.target.value);
  };
  return (
    <div className="theme_header h5 mx-2">
      Select Theme
      <div>
        {themes.map((item, index) => {
          return (
            <Button
              className="m-2"
              key={index}
              value={item.theme_value}
              onClick={handleChange}
            >
              {item.theme_name}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default ThemeSelect;
