import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import Slider from 'react-slick';

// Images
import seatsImg from '@assets/pictures/about-us.png';
import seatsImg1 from '@assets/pictures/aboutus1.jpg';
import seatsImg2 from '@assets/pictures/aboutus2.jpg';
import seatsImg3 from '@assets/pictures/about-us-3.png';
import seatsImg4 from '@assets/pictures/about-us-4.png';
{
  /*
import seatsImg1 from "@assets/pictures/about-us-1.png";
import seatsImg2 from "@assets/pictures/about-us-2.png";
import seatsImg3 from "@assets/pictures/about-us-3.png";
import seatsImg4 from "@assets/pictures/about-us-4.png";*/
}

const AboutUsHeroSlider = () => {
  const [swiping, setSwiping] = useState(false);

  const handleSwipe = () => {
    console.log('swiping', { swiping });
    setSwiping(true);
  };

  const handleClick = () => {
    if (swiping) {
      console.log('clicked, swiping, turned to false', { swiping });
      setSwiping(false);
    } else {
      // Click actions here
    }
  };

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: handleSwipe,
    arrows: true,
    dots: false,
    infinite: true,
    useTransform: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  let images = [
    seatsImg1,
    seatsImg2,
    seatsImg3,
    seatsImg4,
    seatsImg1,
    seatsImg2,
    seatsImg3,
    seatsImg4,
  ];

  return (
    <>
      {/* <section className="container-fluid about_us_hero_slider">
        <div className="row">
          <article className="col-12 px-2">
            <Slider {...heroSlider}>
              {images.map((item, index) => (
                <article key={index} className="px-2">
                  <figure onClick={handleClick}>
                    <img src={item} alt="" />
                  </figure>
                </article>
              ))}
            </Slider>
          </article>
        </div>
      </section> */}
    </>
  );
};

export default withNamespaces()(AboutUsHeroSlider);
