import React from 'react';
import { withNamespaces } from 'react-i18next';

const CenteredTitle = (props) => {
  const { t, classes = '', firstHalf = '', secondHalf = '' } = props;
  return (
    <>
      <h1 className={`centered_title ${classes}`}>
        <span style={{ textTransform: 'capitalize' }}>{firstHalf}</span>&nbsp;
        <span style={{ textTransform: 'capitalize' }}>{secondHalf}</span>
      </h1>
    </>
  );
};

export default withNamespaces()(CenteredTitle);
