import React, { useEffect, useState, useRef, useContext } from 'react';
import { withNamespaces } from 'react-i18next';
import { Row, Container, Col, Dropdown, Modal, Alert } from 'react-bootstrap';
import cinemaBg from '@assets/pictures/cinema-banner.png';
import cinema from '@assets/pictures/cinema.png';
import whiteArrowDown from '@assets/pictures/svgs/white-arrow-down.svg';
import map from '@assets/pictures/svgs/map.svg';
import Slider from 'react-slick';
import AuditoriumCard from './partials/AuditoriumCard.jsx';
import AncilliaryHeroSection from './partials/AncilliaryHeroSection.jsx';
import { fetchCinemas } from '../store/movies/movieActions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fetchAllCinemas, selectedCinema } from '@store/cinemas/cinemasActions';
import Spinner from './partials/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import Nav from 'react-bootstrap/Nav';
// import EventImg1 from '@assets/pictures/RIZ05053.JPG';
// import EventImg1 from '@assets/pictures/P1.jpg';
import EventImg1 from '@assets/pictures/DALMA PREMIERE 5.jpg';
// import EventImg2 from '@assets/pictures/RIZ04736.JPG';
import EventImg2 from '@assets/pictures/sample-bg.png';
import EventImg3 from '@assets/pictures/RIZ04064.JPG';
// import EventVideo1 from '@assets/pictures/video_cinema.MP4';
// import EventVideo1 from '@assets/pictures/star_video.mp4';
import EventVideo1 from '@assets/pictures/mohanlal_video.mp4';
// import EventVideo2 from '@assets/pictures/iratta.mp4';
import EventVideo2 from '@assets/pictures/Al ain FC.mp4';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import AdvertiseForm from './AdvertiseForm.jsx';
import PageService from '@apiService/PageService.js';

const Events = ({ t }) => {
  const dispatch = useDispatch();
  //   const cinemas = useSelector((state) => state.cinemas);
  //   const { cinemasLoading, allCinemas } = cinemas;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [data, setData] = useState([]);

  const history = useHistory();
  // const [cinemaDetails, setCinemaDetails] = useState(false);
  // if (cinemaDetails === true) {
  //   history.push("/cinema-details");
  // }
  // useEffect(() => {
  //   if (!cinemas?.allCinemas) {
  //     dispatch(fetchAllCinemas());
  //   }
  //   window.scrollTo(0, 0);
  // }, []);
  //   useEffect(() => {
  //     dispatch(fetchAllCinemas());
  //   }, [dispatch]);
  useEffect(() => window.scrollTo(0, 0), []);
  const heroSectionObj = {
    arr: [1],
    backgroundImg: EventImg2,
    // title: t('cinema_listing.maintitle'),
    // msg: t('cinema_listing.maindesc'),
  };
  const [key, setKey] = useState('home');

  const getData = () => {
    let payload = {
        mpm_name: 'updatePage',
        mpm_lang_id: 1,
    };

    PageService.GetPageData(payload)
        .then((r) => {
            console.log('response=>', r.data);
            if (r.data && r.data.status) {
                let nextPosition = true; // Start with true for the first valid image
                const modifiedRecords = r.data.Records.map((record) => {
                    let position;

                    if (record.mpm_d_images === null &&  record.mpm_d_video_url) {
                        position = null;
                        nextPosition = !nextPosition; // Toggle position for the next valid image

                    } else {
                        position = nextPosition; // Use the current position
                        nextPosition = !nextPosition; // Toggle position for the next valid image
                    }

                    return {
                        ...record,
                        position,
                    };
                });
console.log("modifiedRecords=>",modifiedRecords)
                setData(modifiedRecords);
            }
        })
        .catch((err) => {
            console.log('err=>', err);
        });
      }
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <style>
        {`    .banner-image
            {
            width: 100%;
            background-image: url('@/assets/pictures/untitled-27.jpg');
            height: 100%;
            // background-color: purple;
            background-position: center;
            }`}
      </style>
      <div>
        {/* <div className="container-fluid align_new">
          <BreadCrumb firstHalf={`${t('Home')} >`} secondHalf={t('Location')} />
          <CenteredTitle firstHalf={'Updates'} secondHalf={''} />
        </div> */}
        <div className="px-0">
          {/* <section>
            <div className="banner-image">
              <img src={EventImg2} className="banner-image"></img>
            </div>
          </section> */}
          <section className="container-fluid container-xl mt-3 location_wrapper">
            <div className="">
            <p className="awu_sub_text">
                Star Cinemas since its inception have launched an array of events.
                The events range from film screenings to premieres, themed party and
                interactive game nights. Here’s a glimpse of all the stunning events
                that took place at Star Cinemas.
              </p>
              {
                data.length==0 && <div className='mb-3'>
                  <p>No Updates</p>
                </div>
              }
              {data.length > 0 &&
                data.map((ele) => (
                  <Row>
                  {
                    ele.mpm_d_images || ele.mpm_d_video_url ?  <div
                    className={`${
                      isMobile ? `d-flex flex-wrap `: `d-flex ${ele.position?"":"flex-row-reverse" }`
                    }`}
                  >
                    <Col
                      sm={6}
                      lg={6}
                      xl={6}
                      className={`d-flex justify-content-center align-items-center ${
                        isMobile ? 'mb-5' : 'mb-5'
                      }`}
                    >
                      {
                      ele.mpm_d_video_url?
                   <video
                    // width="320" height="240"
                    className="img-fluid awu_img"
                    controls
                  >
                    <source src={ele.mpm_d_video_url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                     :
                      <img src={ele.mpm_d_images} className="img-fluid awu_img" />
                      }
                    </Col>

                    <Col
                  sm={6}
                  lg={6}
                  xl={6}
                  className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                >
                  <div className={` ${isMobile ? ' ' : 'mt-5'}`}>
                    <h4 className="font-weight-bold">
                   {ele.mpm_d_main_name || ele.mpm_d_header_name || "-"}

                    </h4>
                    <p className="awu_sub_text">
                  {
                    ele.mpm_d_description ||"-"
                  }
                    </p>
                  </div>
                </Col>
                  </div>:
                  <div>
                         <Col
                  sm={12}
                  lg={12}
                  xl={12}
                  className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                >
                  <div className={` ${isMobile ? ' ' : 'mt-5'}`}>
                    <h4 className="font-weight-bold">
                   {ele.mpm_d_main_name || ele.mpm_d_header_name || "-"}

                    </h4>
                    <p className="awu_sub_text">
                  {
                    ele.mpm_d_description ||"-"
                  }
                    </p>
                  </div>
                </Col>
                  </div>
                  }
                  </Row>
                ))}
           
              {/* <Row> */}
                {/* <div
                  className={`${
                    isMobile ? 'd-flex flex-wrap flex-column-reverse' : 'd-flex'
                  }`}
                >
                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`d-flex justify-content-center align-items-center ${
                      isMobile ? 'mb-5' : 'mb-5'
                    }`}
                  >
                    <img src={EventImg1} className="img-fluid awu_img" />
                  </Col>

                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                  >
                    <div className={` ${isMobile ? ' ' : 'mt-5'}`}>
                      <h4 className="font-weight-bold">Dalma Premier</h4>
                      <p className="awu_sub_text">
                        An unforgettable evening at Star Cinemas, Al Ghurair Centre
                        for the Dubai Premiere of 'Dalma'. Attended by the film's
                        cast and director, the movie tells the story of a woman who
                        reshapes the tradition of a small island with
                        unforeseen consequences.
                      </p>
                    </div>
                  </Col>
                </div> */}

                {/* <Col
                  sm={6}
                  lg={6}
                  xl={6}
                  className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                >
                  <div className={` ${isMobile ? ' ' : 'mt-5'}`}>
                    <h4 className="font-weight-bold">
                      {' '}
                      Mohanlal Visits Star Cinema Al Ghurair
                    </h4>
                    <p className="awu_sub_text">
                      Star Cinemas was buzzing with excitement as the iconic actor
                      Superstar Mohanlal graced our theatre for a morning movie
                      premiere! Fans were treated to a spectacular fanfare, complete
                      with dance performances and vibrant drum beats. It was a truly
                      unforgettable event, marking another milestone in Star
                      Cinemas' legacy of delivering extraordinary
                      cinematic experiences.
                    </p>
                  </div>
                </Col> */}

                {/* <Col
                  sm={6}
                  lg={6}
                  xl={6}
                  className={`d-flex justify-content-center align-items-center ${
                    isMobile ? 'mb-5' : 'mb-5'
                  }`}
                > */}
                  {/* <img src={AdvertiseImg1} className="img-fluid awu_img" /> */}
                  {/* <video src={EventVideo1} className="img-fluid awu_img"></video> */}
                  {/* <video
                    // width="320" height="240"
                    className="img-fluid awu_img"
                    controls
                  >
                    <source src={EventVideo1} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Col> */}

                {/* <div
                  className={`${
                    isMobile ? 'd-flex flex-wrap flex-column-reverse' : 'd-flex'
                  }`}
                >
                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`d-flex justify-content-center align-items-center ${
                      isMobile ? 'mb-5' : 'mb-5'
                    }`}
                  > */}
                    {/* <img src={EventImg3} className="img-fluid awu_img" /> */}
                    {/* <video
                      // width="320" height="240"
                      className="img-fluid awu_img"
                      controls
                    >
                      <source src={EventVideo2} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video> */}
                  {/* </Col>
                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                  >
                    <div className={` ${isMobile ? ' ' : 'mt-5'}`}>
                      <h4 className="font-weight-bold">
                        Star Cinemas Hosts Exclusive Live Broadcast of Final Match:
                        Al Ain FC vs Yokohama FC
                      </h4>
                      <p className="awu_sub_text">
                        On May 25, 2024, Star Cinemas proudly hosted an exclusive
                        live broadcast of the thrilling football match between Al
                        Ain FC and Yokohama FC. The event took place across 8
                        locations throughout the UAE. We extend our gratitude to all
                        who attended and made this event a resounding success. Stay
                        tuned for more exciting events at Star Cinemas!
                      </p>
                    </div>
                  </Col>
                </div>
              </Row> */}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(Events);
