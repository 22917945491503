import Api from './Api';
import { createQueryString } from './ApiHelper';

export default {
GetPageData(payload){
    let query = createQueryString(payload);
    return Api().get(`api/admin/list-of-pages/${query}`);
  },


}