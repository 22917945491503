import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import SeatPlan from './SeatPlan';
import SeatPlanSingleShow from './SeatPlanSingleShow';
import Button from '@components/partials/Button';
import SeatPlanScreen from './SeatPlanScreen';
import SeatPlanIcons from './SeatPlanIcons';
import { useSelector } from 'react-redux';

const StepThree = (props) => {
  const { t, classes, isPrivate = false, isSingleShow = false } = props;

  let userSelection = useSelector((state) => state.movies.userSelection);
  let seatLayoutLoading = useSelector(
    (state) => state.seatLayout.seatLayoutLoading,
  );
  let seatTypeLoading = useSelector((state) => state.seatLayout.seatTypeLoading);

  const stepThreeRef = useRef();
  const [seatPlanLoading, setSeatPlanLoading] = useState(true);

  // for ondemand
  useEffect(() => {
    if (isPrivate) {
      if (!!seatLayoutLoading) {
        setSeatPlanLoading(true);
      } else {
        setTimeout(() => {
          setSeatPlanLoading(false);
        }, 500);
      }
    }
  }, [isPrivate, seatLayoutLoading]);

  let singleNowshowingConatiner = window.location.href.includes(
    'now-showing-single-show',
  );

  // console.log(singleNowshowingConatiner, 'singleNowshowingConatiner');
  // for now showing
  useLayoutEffect(() => {
    if (!isPrivate) {
      if (!!seatLayoutLoading || !!seatTypeLoading) {
        setSeatPlanLoading(true);
      } else {
        setTimeout(() => {
          setSeatPlanLoading(false);
        }, 500);
      }
    }
  }, [isPrivate, seatLayoutLoading, seatTypeLoading]);

  useEffect(() => {
    const stepThree = stepThreeRef.current;
    if (stepThree) {
      const seatPlan = stepThree.querySelector('.seat_plan');
      const seatPlanIcons = stepThree.querySelector('.seat_plan_icons');
      const seatPlanScreen = stepThree.querySelector('.seat_plan_screen');
      if (seatPlan && seatPlanIcons && seatPlanScreen) {
        const seatPlanWidth = seatPlan.offsetWidth;
        // seatPlanIcons.style.width = `${seatPlanWidth}px`;
        seatPlanScreen.style.width = `${seatPlanWidth}px`;
      }
    }
  }, [stepThreeRef.current, seatPlanLoading]);

  return (
    <>
      <div className={`booking_accordion_step_three ${classes}`} ref={stepThreeRef}>
        {!singleNowshowingConatiner && <SeatPlanScreen isPrivate={isPrivate} />}
        {!!isSingleShow ? (
          <SeatPlanSingleShow loading={seatPlanLoading} isPrivate={false} />
        ) : (
          <SeatPlan loading={seatPlanLoading} isPrivate={isPrivate} />
        )}
      </div>
    </>
  );
};

// Default props
StepThree.defaultProps = {
  classes: '',
};

export default withNamespaces()(StepThree);
