import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../../../customHooks/useIsMobile';
import { useParams } from 'react-router';

// Components
import Spinner from '@components/partials/Spinner';
import SeatCell from './SeatCell';
import { useDispatch, useSelector } from 'react-redux';
import { clearFnbState } from '../../../store/foodAndBeverages/foodAndBeveragesActions';
import {
  cinemaReserveSeat,
  clearSeatSelection,
  fetchSeatLayoutBrij,
  privateScreeningReserveSeat,
  releaseSeat,
  reserveSeats,
  selectSeats,
  setTempReservationId,
  updateSeatLayout,
} from '../../../store/seatLayout/seatLayoutActions';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import { currencyFormatter } from '../../../helper/currencyFormatter';
import {
  onSetCompleteNewTotalAmount,
  onSetCompleteRateCard,
  resetTimer,
  setPayNowTotal,
} from '../../../store/movies/movieActions';
import { useHistory } from 'react-router-dom';
import Button from '@components/partials/Button';
import SeatPlanIcons from './SeatPlanIcons';
import { Modal } from 'react-bootstrap';
import close from '@assets/pictures/svgs/close.svg';
import SpecialInstructionsPopup from '../../partials/popups/SpecialInstructionsPopup';
import SpecialInstructionsPopupRegular from '../../partials/popups/SpecialInstructionsPopupRegular';
import { toast } from 'react-toastify';
import { getValueFromGlobalOptions } from '../../../helper/utils';

import io from 'socket.io-client';
import { BASEURL } from '@config/index';
import { getSeatIconUrl } from '@src/helper';

let socket_options = {
  reconnectionAttempts: 3,
  reconnectionDelay: 10000,
};

const SeatPlan = (props) => {
  const { t, classes, loading, isPrivate } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const seatRowRef = useRef();
  const seatLayoutRef = useRef();

  const { genre_id, movie_id, content_lang_id } = useParams();

  const [specialRequest, setSpecialRequest] = useState('');
  const [minSeatsCompulsory, setMinSeatsCompulsory] = useState(null);
  const [numberOfSeats, setNumberOfSeats] = useState(0);

  const [seats, setSeats] = useState([]);
  const [userSelectedSeatsNew, setUserSelectedSeatsNew] = useState([]);

  let userSelection = useSelector((state) => state.movies.userSelection);
  const reservation_id = useSelector((state) => state.seatLayout.reservation_id);
  const currency = useSelector((state) => state.movies.currency);
  const organization = useSelector((state) => state.movies.organizationDetails);
  const selected_language = useSelector((state) => state.movies.selected_language);

  // for now showing only
  const temp_reservation_id = useSelector(
    (state) => state.seatLayout.reservation_id_temp,
  );
  const globalOptions = useSelector((state) => state.movies.globalOptions);
  const realTimeSeatLayout = getValueFromGlobalOptions(
    globalOptions,
    'ENABLE_SEAT_LAYOUT_REAL_TIME',
  );

  const { lang_name, iso_2, lang_id } = selected_language;
  const { curr_code, curr_id } = currency;
  const { org_kiosk_screen_image_url, dummy_poster: dummyPosterDynamic } =
    organization;

  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;

  const max_seat_selection = useSelector(
    (state) => state.movies.max_seat_selection,
  );

  const seatLayout = useSelector((state) => state.seatLayout.seatLayout);

  // console.log(seatLayout, 'seatLayout');
  const seatLayoutLoading = useSelector(
    (state) => state.seatLayout.seatLayoutLoading,
  );
  const screeningTypes = useSelector((state) => state.movies.screeningTypes);
  const screeningTypesLoading = useSelector(
    (state) => state.movies.screeningTypesLoading,
  );

  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const selected_seats = useSelector((state) => state.seatLayout.seats_selected);
  const booking_type = useSelector((state) => state.movies.booking_type);

  const [alreadyReservedModal, setAlreadyReservedModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalBtnMessage, setShowModalBtnMsg] = useState('');
  const [backendSocket, setBackendSocket] = useState(null);
  const [show, setShow] = useState(false);
  // console.log(show, 'show');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // let selectedSeatsArr = selected_seats?.map((x) => {
  //   return x?.srt_id;
  // });

  // console.log(selectedSeatsArr, 'selectedSeatsArr');

  // const WheelChairSelectedStatus = () => {
  //   if (selected_seats?.map((e) => e?.srt_id?.includes(3))) {
  //     return setShow(!show);
  //   }
  //   return false;
  // };
  // console.log(WheelChairSelectedStatus(), 'WheelChairSelectedStatus');
  const onSelectGroupSeats = (
    sl_group_id,
    singleRow,
    isPrivate,
    max_seat_selection,
    selected_seats,
  ) => {
    let filtered = [];

    singleRow.forEach((singleSeat, seatIndex) => {
      if (
        singleSeat?.sl_group_id === sl_group_id &&
        singleSeat?.seat_reserve_type_id != `8` &&
        singleSeat?.seat_reserve_type_id != `4`
      ) {
        filtered.push({ seatIndex, singleSeat });
      }
    });

    /*if (!isPrivate && max_seat_selection && selected_seats) {
      if (
        (parseInt(selected_seats?.length) + filtered?.length) >= parseInt(max_seat_selection)
      ) {
        toast.error(
          `${t('You can book upto')} ${max_seat_selection} ${t(
            'seatlayout_page.seats',
          )}`,
          {
            toastId: 'maxSeats',
          },
        );
        return;
      }
    }*/

    filtered.forEach((f) => {
      onSelectSeats(f.seatIndex, f.singleSeat, numberOfSeats, true);
    });
  };

  const onSelectSeats = (
    seatTypeIndex,
    SeatTypes,
    numberOfSeats,
    forGroupSeats = false,
  ) => {
    const { sl_seat_name } = SeatTypes;
    dispatch(
      selectSeats({
        seatTypeIndex,
        SeatTypes,
        numberOfSeats,
        booking_type_id: userSelection?.selectedScreeningType?.booking_type_id,
        category_id: isPrivate ? category_id : 2,
      }),
    );

    let doesSeatExist = false; // for now showing only

    const newSeats = [...seats];
    if (newSeats.indexOf(sl_seat_name) > -1) {
      newSeats.splice(newSeats.indexOf(sl_seat_name), 1);
      doesSeatExist = true; // for now showing only
    } else {
      newSeats.push(sl_seat_name);
    }
    // console.log(newSeats, 'newSeats');
    setSeats(newSeats);
    setUserSelectedSeatsNew(SeatTypes);

    if (!isPrivate) {
      if (backendSocket && backendSocket.connected && realTimeSeatLayout === 'Y') {
        console.log({
          realTimeSeatLayout,
          'backendSocket.connected': backendSocket?.connected,
        });

        if (!forGroupSeats) {
          onSelectSeatSocketHandling(SeatTypes, doesSeatExist);
        } else {
          onSelectSeatSocketHandling(SeatTypes, !SeatTypes?.SeatSelected);
        }
      }
    }
  };

  // for now showing only
  const onSelectSeatSocketHandling = useCallback(
    function (singleSeat, doesSeatExist) {
      let payload = {
        seat: singleSeat,
        reservation_id: temp_reservation_id,
        ss_id: userSelection?.selectedShow?.ss_id,
        guest_user_id: parseInt(window.localStorage.getItem('guest_user_id')),
      };

      if (!isPrivate && backendSocket) {
        if (doesSeatExist) {
          backendSocket.emit('deselect-seat', payload);
        } else {
          backendSocket.emit('select-seat', payload);
        }
      }
    },
    [
      isPrivate,
      userSelection.selectedShow,
      userSelection.selectedShow.ss_id,
      temp_reservation_id,
      backendSocket,
    ],
  );

  // for now showing only
  useEffect(() => {
    if (!globalOptions) {
      return;
    }
    let backendSocket2;
    if (!isPrivate) {
      backendSocket2 = io(BASEURL + '/', {
        ...socket_options,
        transports: ['websocket'],
      });
      setBackendSocket(backendSocket2);

      backendSocket2.on('connect', function () {
        console.log('connected to backend');

        if (
          backendSocket2 &&
          backendSocket2.connected &&
          realTimeSeatLayout === 'Y'
        ) {
          console.log('socket init');
          backendSocket2.emit('joined-seat-layout', {
            ss_id: userSelection?.selectedShow?.ss_id,
          });

          backendSocket2.on('seat-layout-changes', function (payload) {
            let { sl_id, status } = payload;
            dispatch(
              updateSeatLayout({
                sl_id,
                status,
              }),
            );
          });

          backendSocket2.on('select-seat-response', function (res) {
            if (!res.status) {
              console.log('select-seat-response status false: ', res);
              toast.error(res.message);
              dispatch(clearSeatSelection());
              dispatch(
                fetchSeatLayoutBrij({
                  screen_id: userSelection?.selectedShow?.screen_id,
                  ss_id: userSelection?.selectedShow?.ss_id,
                  md_id: userSelection?.selectedShow?.md_id,
                }),
              );

              const reservation_id = Math.floor(
                100000000 + Math.random() * 900000000,
              );
              dispatch(setTempReservationId(reservation_id));
              setSeats([]);
            }
          });
        }
      });
    }

    return () => {
      if (!isPrivate) {
        if (backendSocket2) {
          backendSocket2.off('seat-layout-changes');
          backendSocket2.off('select-seat-response');
          backendSocket2.disconnect();
        }
      }
    };
  }, [
    globalOptions,
    isPrivate,
    userSelection.selectedShow,
    userSelection.selectedShow.md_id,
    userSelection.selectedShow.screen_id,
    userSelection.selectedShow.ss_id,
    // realTimeSeatLayout,
  ]);

  const getSeatClass = (status, singleSeat) => {
    if (singleSeat.sst_seat_type === 'Wheelchair') {
      return 'cell wheel-chair';
    } else if (status === 3) {
      return 'cell wheel-chair';
    } else if (singleSeat.sst_seat_type === 'Sofa') {
      return 'cell lounger';
    }
    // else if (singleSeat.sst_seat_type === "Standard" || singleSeat.sst_seat_type === "Normal" || singleSeat.sst_seat_type === "Balkon") {
    // }
    else {
      if (singleSeat.covidBlocked || singleSeat.covidBlockedTemp) {
        return 'cell seat covid-blocked';
      } else {
        return 'cell seat';
      }
      // return "cell";
    }
  };
  const [totalSeatBooked, settotalSeatBooked] = useState(0);
  let totalAmountPrice = useRef(0);
  let totalNetAmout = useRef(0);
  let seatsSelectedRateGroupNew = useRef([]);
  const [rateCardGroups, setrateCardGroups] = useState([]);

  const rateCardGroupsFunction = useCallback(() => {
    if (selected_seats.length > 0) {
      let array = [];
      let checkCheckoutRateCardNeeded = 0;
      settotalSeatBooked(0);
      totalAmountPrice.current = 0;
      totalNetAmout.current = 0;
      selected_seats.map((x) => {
        let find_In = array.findIndex((new1) => new1.sst_id === x.sst_id);

        if (find_In >= 0) {
          let defaultRateCardIndex = (
            array[find_In]['rate_card_group'] || []
          ).findIndex((x) => x?.default_rate_card === 'Y');
          defaultRateCardIndex =
            defaultRateCardIndex < 0 ? 0 : defaultRateCardIndex;

          array[find_In]['AllSeats'].push(x);

          // if (array[find_In]["rate_card_group"].length == 1) {
          array[find_In]['totalSelected'] = array[find_In]['totalSelected'] + 1;

          array[find_In]['rate_card_group'][
            defaultRateCardIndex
          ].totalSeatSelected =
            array[find_In]['rate_card_group'][defaultRateCardIndex]
              .totalSeatSelected + 1;

          totalAmountPrice.current =
            parseFloat(totalAmountPrice.current) +
            parseFloat(
              array[find_In]['rate_card_group'][defaultRateCardIndex]
                .rc_total_amount,
            );

          totalNetAmout.current =
            parseFloat(totalNetAmout.current) +
            parseFloat(
              array[find_In]['rate_card_group'][defaultRateCardIndex].rc_net_amount,
            );

          settotalSeatBooked((prev) => prev + 1);
          // } else if
          if (array[find_In]['rate_card_group'].length > 1) {
            checkCheckoutRateCardNeeded = checkCheckoutRateCardNeeded + 1;
          }
        } else {
          let arrayCardGroup = [];
          x.rate_card_group.map((rg) => {
            rg['totalSeatSelected'] = 0;
            arrayCardGroup.push(rg);
          });
          let obj = {
            srt_name: x.srt_name,
            sst_seat_type: x.sst_seat_type,
            sst_id: x.sst_id,
            AllSeats: [x],
            totalSelected: 0,
            rate_card_group: arrayCardGroup,
          };
          let defaultRateCardIndex = (obj['rate_card_group'] || []).findIndex(
            (x) => x?.default_rate_card === 'Y',
          );
          defaultRateCardIndex =
            defaultRateCardIndex < 0 ? 0 : defaultRateCardIndex;

          // if (arrayCardGroup.length == 1) {
          obj['totalSelected'] = 1;
          obj['rate_card_group'][defaultRateCardIndex].totalSeatSelected = 1;
          totalAmountPrice.current =
            parseFloat(totalAmountPrice.current) +
            parseFloat(
              obj['rate_card_group'][defaultRateCardIndex].rc_total_amount,
            );
          totalNetAmout.current =
            parseFloat(totalNetAmout.current) +
            parseFloat(obj['rate_card_group'][defaultRateCardIndex].rc_net_amount);
          settotalSeatBooked((prev) => prev + 1);
          // } else if
          if (arrayCardGroup.length > 1) {
            checkCheckoutRateCardNeeded = checkCheckoutRateCardNeeded + 1;
          }

          array.push(obj);
        }
      });
      seatsSelectedRateGroupNew.current = array;
      // console.log({ seatsSelectedRateGroupNew });

      if (
        checkCheckoutRateCardNeeded == 0
        // &&
        // array[0]["rate_card_group"].length > 0
      ) {
        dispatch(
          onSetCompleteRateCard({
            status: true,
            seatGroup: seatsSelectedRateGroupNew.current,
          }),
        );
        dispatch(
          onSetCompleteNewTotalAmount({
            totalAmount: totalAmountPrice.current,
            netAmount: totalNetAmout.current,
          }),
        );
      } else if (checkCheckoutRateCardNeeded > 0) {
        dispatch(
          onSetCompleteRateCard({
            status: false,
            seatGroup: seatsSelectedRateGroupNew.current,
          }),
        );
      }
      setrateCardGroups(array);
    } else {
      seatsSelectedRateGroupNew.current = [];
    }
  }, [selected_seats]);

  const totalCount = (rg, index, rg_index, type) => {
    console.log('seatsSelectedRateGroupNew', seatsSelectedRateGroupNew.current);
    let x = seatsSelectedRateGroupNew.current[index]['rate_card_group'][rg_index];
    if (
      type == 'increment' &&
      seatsSelectedRateGroupNew.current[index].totalSelected ==
        seatsSelectedRateGroupNew.current[index].AllSeats.length
    ) {
      return false;
    }

    if (
      type == 'increment' &&
      totalSeatBooked + 1 <= selected_seats.length &&
      x['totalSeatSelected'] <= selected_seats.length
    ) {
      x['totalSeatSelected'] = x['totalSeatSelected'] + 1;
      seatsSelectedRateGroupNew.current[index].totalSelected =
        seatsSelectedRateGroupNew.current[index].totalSelected + 1;
    } else if (
      seatsSelectedRateGroupNew.current[index].totalSelected >= 1 &&
      type == 'decrement' &&
      x['totalSeatSelected'] >= 1
    ) {
      x['totalSeatSelected'] = x['totalSeatSelected'] - 1;
      seatsSelectedRateGroupNew.current[index].totalSelected =
        seatsSelectedRateGroupNew.current[index].totalSelected - 1;
    }
    let totalAmount = 0;
    let totalSeatBooked2 = 0;
    let totalNetAmout2 = 0;
    seatsSelectedRateGroupNew.current.map((z) => {
      z.rate_card_group.map((y) => {
        totalAmount =
          parseFloat(totalAmount) +
          parseFloat(y.totalSeatSelected) * parseFloat(y.rc_total_amount);

        totalNetAmout2 =
          parseFloat(totalNetAmout2) +
          parseFloat(y.totalSeatSelected) * parseFloat(y.rc_net_amount);
      });

      totalSeatBooked2 = parseInt(totalSeatBooked2) + parseInt(z.totalSelected);
    });
    totalAmountPrice.current = totalAmount;

    settotalSeatBooked(totalSeatBooked2);
    totalNetAmout.current = totalNetAmout2;
    x['tt_name'] = x['tt_name'] + ' ';
    if (totalSeatBooked2 == selected_seats.length) {
      dispatch(
        onSetCompleteRateCard({
          status: true,
          seatGroup: seatsSelectedRateGroupNew.current,
        }),
      );
      dispatch(
        onSetCompleteNewTotalAmount({
          totalAmount: totalAmountPrice.current,
          netAmount: totalNetAmout.current,
        }),
      );
    } else {
      dispatch(
        onSetCompleteRateCard({
          status: false,
          seatGroup: seatsSelectedRateGroupNew.current,
        }),
      );
    }
  };
  useEffect(() => {
    rateCardGroupsFunction();
  }, [selected_seats]);
  const [reserveSeatError, setReserveSeatError] = useState(null);

  const checkMaxSeatSelection = (
    seat,
    isPrivate,
    max_seat_selection,
    selected_seats,
  ) => {
    if (isPrivate) return true;
    if (max_seat_selection == undefined || max_seat_selection == null) return true;

    if (max_seat_selection && selected_seats?.length) {
      let tempCheck = selected_seats.filter((x) => x.sl_id == seat.sl_id);
      if (tempCheck && tempCheck.length > 0) {
        return true;
      } else if (parseInt(selected_seats.length) >= parseInt(max_seat_selection)) {
        toast.error(
          `${t('You can book upto')} ${max_seat_selection} ${t(
            'seatlayout_page.seats',
          )}`,
          {
            toastId: 'maxSeats',
          },
        );
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const onProceedRateCardGroup = async () => {
    setLoader(true);
    dispatch(
      cinemaReserveSeat({
        selected_seats,
        mycinema_ss_d_id: scheduleDetails?.mycinema_ss_d_id,
        history,
        cinema_id: scheduleDetails?.cinema_id,
        seatGroup: seatsSelectedRateGroupNew?.current,
        booking_type_id: userSelection?.selectedScreeningType?.booking_type_id,
        gogo_booking_type_id: booking_type,
        category_id,
        callback: setLoader,
        screen_id: userSelection?.selectedShow?.screen_id,
        ss_id: userSelection?.selectedShow?.cinema_ss_id,
        md_id: userSelection?.selectedShow?.md_id,
        closeModal: () => setModalShow(false),
        specialRequest,
        genre_id,
        movie_id,
        content_lang_id,
        isPrivate,
        setReserveSeatError,
        setShowModal,
        setModalMessage,
        skipFnbStep: false,
      }),
    );
  };

  const onProceedRateCardGroupNowShowing = async () => {
    setLoader(true);
    dispatch(
      reserveSeats({
        selected_seats,
        schedule_show_id: scheduleDetails?.ss_id,
        history,
        cinema_id: scheduleDetails.cinema_id,
        seatGroup: seatsSelectedRateGroupNew.current,
        gogo_ss_id: scheduleDetails.gogo_ss_id,
        gogo_booking_type_id: scheduleDetails.gogo_booking_type_id,
        category_id: 2, // for now showing
        callback: setLoader,
        screen_id: userSelection?.selectedShow?.screen_id,
        ss_id: userSelection?.selectedShow?.cinema_ss_id,
        md_id: userSelection?.selectedShow?.md_id,
        closeModal: () => setModalShow(false),
        specialRequest,
        genre_id,
        movie_id,
        content_lang_id,
        isPrivate,
        setReserveSeatError,
        setShowModal,
        setModalMessage,
        skipFnbStep: false,
      }),
    ).then((data) => {
      if (data && data.status === false) {
        setAlreadyReservedModal(true);
      }
    });
  };
  const category_id = 1;
  useEffect(() => {
    dispatch(clearSeatSelection());
    dispatch(resetTimer());
    dispatch(clearFnbState());
    if (reservation_id) {
      dispatch(releaseSeat({ reservation_id }));
    }
  }, []);

  useEffect(() => {
    if (userSelection?.selectedScreeningType?.booking_type_id) {
      if (
        screeningTypes &&
        category_id == 1 &&
        userSelection?.selectedScreeningType?.booking_type_id == 1
      ) {
        const x = screeningTypes.find((type) => type.booking_type_id === 1);
        if (x) {
          setNumberOfSeats(x.min_seats);
          setMinSeatsCompulsory(x.min_seats);
        }
      }
    }
  }, [screeningTypes, userSelection.selectedScreeningType]);

  useEffect(() => {
    if (
      userSelection?.selectedScreeningType?.booking_type_id == 1 &&
      userSelection?.selectedScreeningType?.booking_type_id == 2
    ) {
      setNumberOfSeats(undefined);
    }
  }, [category_id, userSelection.selectedScreeningType]);

  useLayoutEffect(() => {}, [selected_seats, userSelection, isPrivate]);

  function percentage(percent, total) {
    return (percent / 100) * total;
  }
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        className="specialy_abled_popup"
      >
        <div className="cross_wrapper">
          <span className="cross_text" onClick={() => handleClose()}>
            &#10006;
          </span>
        </div>
        <div>
          <span style={{ color: 'red' }}>Note</span>:- Space allocated for specially
          abled
        </div>
        {/* <div className="d-flex justify-content-center align-items-center mt-3">
          <button className="confirm_design_btn">Proceed</button>
        </div> */}
      </Modal>
      <div className={`seat_plan_wrapper`} ref={seatLayoutRef}>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          className="seat-type-popup"
          centered
          show={modalShow}
          onHide={() => {
            /*setModalShow(false) */
          }}
        >
          <section className="" style={{ backgroundColor: '#1C1B21' }}>
            <img
              src={close}
              className="close-btn-main"
              onClick={() => setModalShow(!modalShow)}
              alt=""
            />

            <div className="row title-row">
              <div className="col-12 spacing_pop text-center">
                <p className="title">{t('Specify seat type')}</p>
              </div>
              <div className="col-12 text-center">
                <div className="selected-seats">
                  {t('Tickets Selected')} {totalSeatBooked}/{selected_seats.length}
                </div>
              </div>
            </div>
            <div className="seat-picker">
              <div className="seat-picker-row headings">
                <div>
                  <p>{t('Seat Type')}</p>
                </div>
                <div className="align-cen">
                  <p>{t('No. of Seats')}</p>
                </div>
                <div className="align-cen">
                  <p>{t('Price')}</p>
                </div>
              </div>
              {rateCardGroups?.length > 0 &&
                rateCardGroups?.map((item, index) => (
                  <>
                    <div></div>
                    <div
                      className={`seat-type-cat ${
                        isPrivate ? 'seat-type-cat-gold' : ''
                      }`}
                    >
                      {item?.sst_seat_type}
                      {isPrivate &&
                      !!userSelection?.selectedScreeningType?.screening_name
                        ? ` | ${t(
                            userSelection?.selectedScreeningType.screening_name,
                          )?.toUpperCase()}`
                        : ''}
                    </div>

                    {item?.rate_card_group?.map((rg, rg_index) => (
                      <div className="seat-picker-row" key={rg_index}>
                        <div>
                          <p>
                            {rg?.ticketTypeTranslations?.[iso_2?.toLowerCase()] ||
                              rg.tt_name}
                          </p>
                          <p className="pb-1">
                            {curr_code} {currencyFormatter(rg.rc_total_amount)}
                          </p>
                        </div>
                        <div className="">
                          <div className="modifier justify-content-center">
                            {item?.rate_card_group?.length > 1 && (
                              <span
                                className={`${
                                  rg.totalSeatSelected == 0 ? 'inactive' : ''
                                } ${isPrivate ? 'text-golden-hov' : ''} m_inline10`}
                                onClick={() =>
                                  totalCount(rg, index, rg_index, 'decrement')
                                }
                                style={{
                                  alignItems: isMobile ? 'baseline' : 'flex-end',
                                }}
                              >
                                -
                              </span>
                            )}
                            <span
                              className={`${`rg.totalSeatSelected == "0" ? "inactive" : ""`}`}
                            >
                              {rg.totalSeatSelected}
                            </span>
                            {item?.rate_card_group?.length > 1 && (
                              <span
                                className={`
                            ${
                              item.totalSelected == item.AllSeats.length
                                ? 'inactive'
                                : ''
                            } ${isPrivate ? 'text-golden-hov' : ''} m_inline10`}
                                onClick={() =>
                                  totalCount(rg, index, rg_index, 'increment')
                                }
                              >
                                +
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text_wrap_mob align-cen">
                          <p>
                            {curr_code}{' '}
                            {currencyFormatter(
                              rg.rc_total_amount * rg.totalSeatSelected,
                            )}
                          </p>
                        </div>
                        <div className="desc_section">
                        {rg?.rc_description && (
                           <p className="pb-1 rcDescription">
                            {rg?.rc_description}
                          </p>
                          )}
                        </div>
                        <div className="pop_hori_div">
                          <hr className="pop_up_hori" />
                        </div>
                      </div>
                    ))}
                  </>
                ))}
              <div className="seat-picker-row total">
                <div>
                  <p>{t('Total')}</p>
                </div>
                <div className="text_wrap_mob align-cen">
                  <p>
                    {curr_code} {currencyFormatter(totalAmountPrice.current)}
                  </p>
                </div>
                <div className="pop_hori_div">
                  <hr className="pop_up_hori" />
                </div>
              </div>
            </div>

            {reserveSeatError && <p className="error">{reserveSeatError}</p>}
            {/* {totalSeatBooked === selected_seats.length && ( */}
            <button
              className={`btn-main btn-main-lg mx-auto btn_pop_spacing  ${
                loader && 'loader-text'
              }  ${isPrivate ? ' golden_btn_pvt ' : ''} ${
                totalSeatBooked === selected_seats.length ? '' : ' inactive '
              }`}
              onClick={() => {
                if (!!isPrivate) {
                  console.log('ON PROCEED ON-DEMAND');
                  totalSeatBooked == selected_seats.length &&
                    onProceedRateCardGroup();
                } else {
                  totalSeatBooked == selected_seats.length &&
                    onProceedRateCardGroupNowShowing();
                }
              }}
            >
              {t('Confirm')}
            </button>
            {/* )} */}
          </section>
        </Modal>
        {loading || !seatLayout || seatLayout?.length === 0 ? (
          <>
            <div className="text-center">
              <Spinner />
            </div>
          </>
        ) : (
          <>
            <table className="seat_plan">
              <section className="row">
                <div className="px-3 mx-auto">
                  <div className="seat-selection-heading">
                    {minSeatsCompulsory &&
                      selected_seats.length < numberOfSeats && (
                        <p
                          className=""
                          style={{ height: isMobile ? '25px' : undefined }}
                        >
                          {t('Min.')} {numberOfSeats} {t('seats required')}
                        </p>
                      )}
                    {minSeatsCompulsory &&
                      selected_seats.length >= numberOfSeats && (
                        <p className="text-success">
                          {selected_seats.length} {t('Seats Selected')}
                        </p>
                      )}
                  </div>
                </div>
              </section>

              {/* LOOP SEAT TYPES */}
              {seatLayout?.map((seatTypes, seatTypeIndex) => (
                <>
                  <tr key={seatTypeIndex}>
                    <td>
                      <tr>
                        <td>
                          <table className="seat_plan_type">
                            {/* {isMobile && */}
                            <div
                              className="seat_plan_type_info_mobile"
                              style={{
                                marginBottom: seatTypes?.sst_id == 4 ? '0px' : '',
                              }}
                            >
                              <p>
                                {seatTypes?.seatType}
                                {/* &nbsp;{curr_code}{' '}
                                {currencyFormatter(seatTypes?.seat_price)} */}
                              </p>
                            </div>
                            {/*  } */}
                            <tr>
                              <td>
                                <table>
                                  {/* LOOP SEAT ROWS */}
                                  {seatTypes.seatsFinal.map(
                                    (singleRow, rowIndex) => (
                                      <tr
                                        key={`${seatTypeIndex}-${rowIndex}`}
                                        ref={seatRowRef}
                                      >
                                        <td>
                                          <SeatCell
                                            char={
                                              (window.location.href.includes(
                                                'star'.toLowerCase(),
                                              ) ||
                                                window.location.href.includes(
                                                  'localhost'.toLowerCase(),
                                                )) &&
                                              singleRow
                                                .filter(
                                                  (r) => !!r?.sl_seat_name,
                                                )?.[0]
                                                ?.sl_seat_name?.[0].includes('1')
                                                ? rowIndex + 1
                                                : singleRow.filter(
                                                    (r) => !!r?.sl_seat_name,
                                                  )?.[0]?.sl_seat_name?.[0] ?? ' '
                                            }
                                          />
                                        </td>
                                        <td>
                                          <table>
                                            <tr>
                                              {/* LOOP SEATS */}
                                              {singleRow.map(
                                                (singleSeat, seatIndex) => (
                                                  <td
                                                    key={`${seatTypeIndex}-${rowIndex}-${seatIndex}`}
                                                    className={``}
                                                  >
                                                    <p className="selected_saet_name">
                                                      {singleSeat?.SeatSelected
                                                        ? singleSeat.sl_seat_name
                                                        : ''}
                                                    </p>

                                                    <SeatCell
                                                      normalIcon={getSeatIconUrl(
                                                        singleSeat.srt_online_image_url,
                                                      )}
                                                      selectedIcon={getSeatIconUrl(
                                                        singleSeat.srt_online_image_url_selected,
                                                      )}
                                                      disabledIcon={getSeatIconUrl(
                                                        singleSeat.srt_online_image_url_disabled,
                                                      )}
                                                      isPrivate={
                                                        userSelection
                                                          ?.selectedScreeningType
                                                          ?.booking_type_id === 3
                                                      }
                                                      disabled={
                                                        singleSeat.seat_reserve_type_id ==
                                                          `8` ||
                                                        singleSeat.seat_reserve_type_id ==
                                                          `4`
                                                      }
                                                      isSeatSelected={
                                                        !!singleSeat?.SeatSelected
                                                      }
                                                      char={
                                                        singleSeat.srt_id == 2 ||
                                                        singleSeat.srt_id === 12 ||
                                                        singleSeat.srt_id === 13 ? (
                                                          <></>
                                                        ) : undefined
                                                      }
                                                      onClick={(seatCellRef) => {
                                                        // console.log({
                                                        //   rowIndex,
                                                        //   seatIndex,
                                                        //   singleRow,
                                                        //   singleSeat,
                                                        //   seatCellRef,
                                                        //   'singleSeat.covidBlocked':
                                                        //     singleSeat?.covidBlocked,
                                                        //   'singleSeat.covidBlockedTemp':
                                                        //     singleSeat?.covidBlockedTemp,
                                                        // });
                                                        // if (
                                                        //   WheelChairSelectedStatus() ==
                                                        //   true
                                                        // ) {
                                                        //   setShow(true);
                                                        // }
                                                        // WheelChairSelectedStatus();
                                                        if (
                                                          singleSeat?.srt_id == 3 &&
                                                          !selected_seats?.some(
                                                            (x) =>
                                                              x?.sl_id ==
                                                              singleSeat?.sl_id,
                                                          )
                                                        ) {
                                                          setShow(true);
                                                        }
                                                        if (
                                                          singleSeat.srt_id == 2 ||
                                                          singleSeat.srt_id ===
                                                            12 ||
                                                          singleSeat.srt_id === 13
                                                        )
                                                          return;

                                                        if (
                                                          !singleSeat?.covidBlocked &&
                                                          !singleSeat?.covidBlockedTemp &&
                                                          singleSeat.seat_reserve_type_id !=
                                                            `8` &&
                                                          singleSeat.seat_reserve_type_id !=
                                                            `4` &&
                                                          checkMaxSeatSelection(
                                                            singleSeat,
                                                            isPrivate,
                                                            max_seat_selection,
                                                            selected_seats,
                                                          )
                                                        ) {
                                                          if (
                                                            singleSeat.srt_multi_select &&
                                                            singleSeat.srt_multi_select ==
                                                              'Y' &&
                                                            scheduleDetails?.turnOffMultiSeatSelection !=
                                                              'Y' &&
                                                            !!singleSeat.sl_group_id
                                                          ) {
                                                            onSelectGroupSeats(
                                                              singleSeat.sl_group_id,
                                                              singleRow,
                                                              isPrivate,
                                                              max_seat_selection,
                                                              selected_seats,
                                                            );
                                                          } else {
                                                            onSelectSeats(
                                                              seatIndex,
                                                              singleSeat,
                                                              numberOfSeats,
                                                            );
                                                          }
                                                        }
                                                      }}
                                                    />
                                                  </td>
                                                ),
                                              )}
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    ),
                                  )}
                                </table>
                              </td>

                              {/* WAS OVERLAPPING WHEN THERE IS ONLY ONE ROW IN A SEAT LAYOUT, SO REMOVED */}
                              {/* {!isMobile &&
                            userSelection?.selectedScreeningType?.booking_type_id !== 3 &&
                            <div className="seat_plan_type_info">
                              <h5>{seatTypes?.seatType}</h5>
                              <h6>
                                {t('Starts from')}

                              </h6>
                              <h4>
{curr_code}{" "}
                                {currencyFormatter(seatTypes?.seat_price)}
                              </h4>
                            </div>} */}
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </td>
                  </tr>
                </>
              ))}
            </table>
          </>
        )}
      </div>

      <div className={`seat_plan_wrapper`}>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          className="seat-type-popup"
          centered
          show={alreadyReservedModal}
          onHide={() => {
            setAlreadyReservedModal(false);
          }}
        >
          <section className="" style={{ backgroundColor: 'var(--dark-1)' }}>
            <img
              src={close}
              className="close-btn-main"
              onClick={() => {
                setAlreadyReservedModal(false);
              }}
              alt=""
            />

            <div className="row title-row">
              <div className="col-12 spacing_pop text-center">
                <br />
                <p className="title">
                  {'Sorry, one of the seats you selected has already been booked.'}
                </p>
              </div>
            </div>
          </section>
        </Modal>
      </div>

      {!loading && seatLayout?.length > 0 && <SeatPlanIcons />}

      {!isMobile && isPrivate && (
        <Button
          onClick={() => {
            setModalShow(true);
            rateCardGroupsFunction();
          }}
          classes={`mx-auto mt-4 ${isPrivate ? ' golden_btn_pvt ' : ''} ${
            (selected_seats.length === 0 ||
              numberOfSeats === undefined ||
              selected_seats.length < numberOfSeats) &&
            ' inactive '
          }`}
          text={t('Book Now')}
          size="lg"
        />
      )}
      {!isMobile && !isPrivate && (
        <Button
          onClick={() => {
            console.log({
              selected_seats,
              numberOfSeats,
              booking_type_id:
                userSelection?.selectedScreeningType?.booking_type_id,
            });

            setModalShow(true);
            rateCardGroupsFunction();
          }}
          classes={`mx-auto mt-4 ${isPrivate ? ' golden_btn_pvt ' : ''} ${
            selected_seats.length === 0 && ' inactive '
          }`}
          text={t('Book Now')}
          size="lg"
        />
      )}
      {!!isMobile && seats.length > 0 && (
        <section className={`pay_now ${isPrivate ? 'back_gold' : ''}`}>
          <article>
            <p>{t('Total Payable')}</p>

            <small>
              Prices Including VAT
              {/* (Incl. of 2.5% VAT - {curr_code}{' '}
              {currencyFormatter(
                totalAmountPrice?.current
                  ? percentage(2.5, totalAmountPrice.current)
                  : 0,
              )}
              ) */}
            </small>
            <h6>
              {curr_code} {currencyFormatter(totalAmountPrice?.current ?? 0)}
            </h6>
          </article>
          <Button
            onClick={() => {
              setModalShow(true);
              rateCardGroupsFunction();
            }}
            text={t('PAY NOW')}
            classes={`${isPrivate ? ' gold_hov text_gold ' : ''} ${
              (selected_seats.length === 0 ||
                numberOfSeats === undefined ||
                selected_seats.length < numberOfSeats) &&
              ' inactive '
            }`}
          />
        </section>
      )}
    </>
  );
};

export default withNamespaces()(SeatPlan);
