import { withNamespaces } from 'react-i18next';
import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';

// Components
import MoviesNFestsNew from '@components/partials/MoviesNFestsNew';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getLangSpecificAttribute } from '@helper/languages';
import SpinnerComp from './partials/Spinner';
import {
  useGetMiniFestivalQuery,
  useGetNowShowingQuery,
  useGetOnDemandQuery,
  useGetUpcomingQuery,
} from '@store/rtk/rtk.movieLists';

// Images

const ShowMovieListStacked = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { category_id, section_id } = useParams();

  const organization = useSelector((state) => state.movies.organizationDetails);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;

  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser } = loginDetails;

  const { data: comingSoonMovies, isLoading: comingSoonLoading } =
    useGetUpcomingQuery();
  // const { data: createOwnShowMovies, isLoading: onDemandLoading } =
  //   useGetOnDemandQuery({
  //     limit: 1000,
  //     currentPage: 1,
  //     isUserLogged: loggedInUser ? 'Y' : 'N',
  //   });
  const { data: todaysScreeningsMovies, isLoading: nowShowingLoading } =
    useGetNowShowingQuery({ limit: 1000, currentPage: 1 });
  // const { data: miniFestivalsData, isLoading: miniFestivalsLoading } =
  //   useGetMiniFestivalQuery();

  // const uniqueCreateOwnShowMovies = {
  //   ...createOwnShowMovies,
  //   data: [
  //     ...new Map(
  //       createOwnShowMovies?.data?.map((item) => [item['movie_id'], item]),
  //     ).values(),
  //   ],
  // };

  const uniqueComingSoonMovies = {
    ...comingSoonMovies,
    data: [
      ...new Map(
        comingSoonMovies?.data?.map((item) => [item['movie_id'], item]),
      ).values(),
    ],
  };

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (!!category_id) {
      // now_showing
      if (category_id == 1) {
      }
      // on_demand
      else if (category_id == 2) {
      }
      // show_upcoming
      else if (category_id == 3) {
      }
      // miniFestivals
      else if (category_id == 4) {
      }
      // not found
      else {
        setLoader(false);
      }
    }
  }, [category_id, section_id]);

  if (comingSoonLoading || nowShowingLoading)
    return (
      <div className="row">
        <div className="col-12 text-center">
          <SpinnerComp />
        </div>
      </div>
    );

  return (
    <>
      <div className="container-fluid mt-4">
        {/*{category_id == 4 && (
          <>
             Movie Sections
            {miniFestivalsData?.Records?.length > 0 &&
              miniFestivalsData?.Records?.filter(
                (x) => x.unique_id == section_id,
              ).map(
                (category, index) =>
                  category?.movie_data?.data?.length > 0 && (
                    <MoviesNFestsNew
                      key={index}
                      section={2}
                      moviesArray={category?.movie_data?.data}
                      subCategory={
                        getLangSpecificAttribute(
                          category?.language_content,
                          lang_id,
                          'event_name',
                          'lang_id',
                        ) || category?.category_link_name
                      }
                      category={t('On Demand')}
                      type="on_demand"
                      movieCount={category?.movie_data?.data?.length}
                    />
                  ),
              )}

            {miniFestivalsData?.Records?.length === 0 && (
              <>
                <section className="row">
                  <div className="col-12 text-center">
                    <h6 className="mx-auto mb-0">{t('common.No movies found')}</h6>
                  </div>
                </section>
              </>
            )}
          </>
        )}*/}

        {category_id == 3 && (
          <>
            {uniqueComingSoonMovies?.data?.length > 0 && (
              <MoviesNFestsNew
                section={4}
                type="show_upcoming"
                moviesArray={uniqueComingSoonMovies?.data}
                subCategory={''}
                category={t('Upcoming')}
                movieCount={uniqueComingSoonMovies?.data?.length}
              />
            )}

            {uniqueComingSoonMovies?.data?.length === 0 && (
              <>
                <section className="row">
                  <div className="col-12 text-center">
                    <h6 className="mx-auto mb-0">{t('common.No movies found')}</h6>
                  </div>
                </section>
              </>
            )}
          </>
        )}

        {/*{category_id == 2 && (
          <>
            {uniqueCreateOwnShowMovies?.data?.length > 0 && (
              <MoviesNFestsNew
                section={2}
                type="on_demand"
                moviesArray={uniqueCreateOwnShowMovies?.data}
                subCategory={''}
                category={t('On Demand')}
                movieCount={uniqueCreateOwnShowMovies?.data?.length}
              />
            )}

            {uniqueCreateOwnShowMovies?.data?.length === 0 && (
              <>
                <section className="row">
                  <div className="col-12 text-center">
                    <h6 className="mx-auto mb-0">{t('common.No movies found')}</h6>
                  </div>
                </section>
              </>
            )}
          </>
        )}*/}

        {category_id == 1 && (
          <>
            {todaysScreeningsMovies?.data?.length > 0 && (
              <MoviesNFestsNew
                section={1}
                moviesArray={todaysScreeningsMovies?.data}
                subCategory={''}
                category={t('Now Showing')}
                movieCount={todaysScreeningsMovies?.data?.length}
                type="now_showing"
              />
            )}

            {todaysScreeningsMovies?.data?.length === 0 && (
              <>
                <section className="row">
                  <div className="col-12 text-center">
                    <h6 className="mx-auto mb-0">{t('common.No movies found')}</h6>
                  </div>
                </section>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default withNamespaces()(ShowMovieListStacked);
