import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import back from '@assets/pictures/svgs/back-modal.svg';
import close from '@assets/pictures/svgs/close.svg';
import { withNamespaces } from 'react-i18next';
import { otpValidation } from '@helper/formatting';
import showPasswordIcon from '@assets/pictures/svgs/show-password.svg';
import hidePasswordIcon from '@assets/pictures/svgs/hide-password.svg';
import { motion } from 'framer-motion';

import {
  forgotPassword,
  verifyOTP,
  resetPassword,
  resetTimer,
  startTimer,
} from '../../../store/auth/authActions';
// const handleChangeOtp = (value) => {
//   setOtp(value);
// };
const OtpPopup = (props) => {
  const {
    t,
    onClickResend,
    allowResend,
    setField,
    field,
    setAllowResend,
    loadingResendOtp,
  } = props;
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(``);
  const [newPassword, setNewPassword] = useState(``);
  const state = useSelector((state) => state);
  const {
    auth: {
      forgotPasswordError,
      forgotPasswordUserDetails,
      otpError,
      timer: { seconds, minutes },
      timerCompleted,
    },
  } = state;
  const [step, setStep] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLoginPassword, setShowLoginPassword] = useState(false);

  const onClickConfirm = () => {
    switch (step) {
      case 1:
        setNewPassword('');
        setSubmitted(true);
        if (field === ``) return;
        setLoading(true);
        dispatch(
          forgotPassword({
            field,
            callback: setLoading,
            successCallback: () => {
              setSubmitted(false);
              setStep(step + 1);
              // setAllowResend(false);
              dispatch(resetTimer());
              dispatch(startTimer());
              // setTimeout(() => setAllowResend(true), 30000);
            },
          }),
        );
        break;

      case 2:
        setSubmitted(true);
        if (!otp) return;
        setLoading(true);
        dispatch(
          verifyOTP({
            otp,
            callback: setLoading,
            successCallback: () => {
              setSubmitted(false);
              setStep(step + 1);
            },
          }),
        );
        break;
      case 3:
        setSubmitted(true);
        if (!newPassword) return;
        setLoading(true);
        dispatch(
          resetPassword({
            newPassword,
            callback: setLoading,
            successCallback: () => {
              setOtp('');
              setField('');
              setSubmitted(false);
              setStep(step + 1);
            },
          }),
        );
        break;
      case 4:
        setField('');
        setSubmitted(false);
        setOtp('');
        setStep(1);
        props.closeFunc(false);
        break;
      default:
    }
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="otp-popup"
      centered
      onHide={() => {
        setOtp('');
        setSubmitted(false);
        setStep(1);
        setLoading(false);
        setShowLoginPassword(false);
        props.setField('');
        props.closeFunc(false);
        props.loginModalCloseFunc(false);
      }}
    >
      <section className="wrapper container-fluid">
        {step <= 2 && (
          <img
            // src={back}
            src={step > 1 ? back : close}
            className={`back-btn-main ${step < 2 ? '__to_right' : ''}`}
            onClick={() => {
              step > 1 ? setStep(step - 1) : props.closeFunc(false);
              if (step >= 2) {
                setOtp('');
              }
            }}
            alt=""
          />
        )}

        <p className="title">
          {step === 1
            ? t(`login_form.forgot-password`)
            : step === 2
            ? t(`Enter OTP`)
            : step === 3
            ? t(`form_general.reset-pwd`)
            : step === 4
            ? t(`Password_Changed_Succ`)
            : null}
        </p>

        <form onSubmit={(e) => e.preventDefault()} className="user-form">
          {step === 1 && (
            <>
              <label>
                <span>{t('login_form.placeholders.email-mobile')}</span>
                <input
                  type="text"
                  placeholder={t(`Enter_email_or_mobile`)}
                  value={field}
                  onChange={(e) => setField(e.target.value)}
                />
              </label>
              <p className="error">
                {submitted && field === '' && t('field_required')}
              </p>
            </>
          )}
          {step === 3 && (
            <>
              <label className="position-relative">
                <span>{t(`login_form.placeholders.pwd`)}</span>
                <input
                  type={showLoginPassword ? 'text' : 'password'}
                  autocomplete="new-password"
                  placeholder={t('login_form.placeholders.Enter your password')}
                  min="4"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {!showLoginPassword && (
                  <img
                    alt=""
                    width="30"
                    height="30"
                    className="show-password-icon show-password-icon-3"
                    src={showPasswordIcon}
                    onClick={() => setShowLoginPassword((x) => !x)}
                  />
                )}
                {showLoginPassword && (
                  <img
                    alt=""
                    width="30"
                    height="30"
                    className="show-password-icon show-password-icon-3"
                    src={hidePasswordIcon}
                    onClick={() => setShowLoginPassword((x) => !x)}
                  />
                )}
              </label>
              <p className="error">
                {submitted && newPassword === '' && t('field_required')}
              </p>
            </>
          )}
        </form>

        {step === 2 && forgotPasswordUserDetails && (
          <>
            <p className="msg">
              {t(`Enter OTP sent to`)} {forgotPasswordUserDetails?.cust_email}{' '}
              {t(`and`)} {forgotPasswordUserDetails?.cust_mobile}
            </p>
            <div className="otp-input mb-1">
              {!timerCompleted && (
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => {
                    if (otpValidation(e.target.value)) {
                      setOtp(e.target.value);
                    }
                  }}
                />
              )}
              {/* <OtpInput
                value={otp}
                onChange={(otp) => setOtp(otp)}
                numInputs={6}
                separator={<span></span>}
              /> */}
            </div>
            {/* {submitted && !otp && ( */}
            {submitted && !otp && <p className="error">{t('field_required')}</p>}
            {/* )} */}
            {/* {true && (
              <button
                onClick={onClickResend}
                className={`btn-main mx-auto ${!allowResend && `inactive`}`}
              >
                {t(`Resent OTP`)}
              </button>
            )} */}
            {!timerCompleted && (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {minutes.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
                :
                {seconds.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
                &nbsp;
              </motion.p>
            )}
            {timerCompleted && (
              <>
                {' '}
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p>{t(`otp_message`)}&nbsp;</p>
                  <button
                    className={`btn-basic ml-2 ${
                      loadingResendOtp ? 'loader-text' : ''
                    }`}
                    onClick={() => {
                      setOtp('');
                      onClickResend && onClickResend();
                    }}
                    // className={`btn-main mx-auto ${!allowResend && `inactive`}`}
                  >
                    {t(`Resend`)}
                  </button>
                </div>
              </>
            )}
          </>
        )}
        {forgotPasswordError && <p className="error">{t(forgotPasswordError)}</p>}
        {otpError && <p className="error">{t(otpError)}</p>}
        <button
          className={`btn-main mt-4 ${loading && `loader-text`}`}
          onClick={onClickConfirm}
        >
          {step === 4 ? t(`Close`) : t(`Confirm`)}
        </button>
      </section>
    </Modal>
  );
};

export default withNamespaces()(OtpPopup);
