// JobPage.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const JobPage = () => {
  return (
    <>
      <Container className="mt-5 w-80 mx-auto">
        {/*<h1 className="mb-4 font-weight-bold">Available Positions</h1>
        <Row>
          <Col>
            <h4 className='font-weight-bold'>Position:-</h4>
            <p>Software Developer</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className='font-weight-bold'>Job Location:-</h4>
            <p>City, Country</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className='font-weight-bold'>Job Description:-</h4>
            <p className='text-justify'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum euismod, nulla vitae tincidunt commodo, ligula elit
              vulputate odio, nec ultricies nisi nibh vel nisl.
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Link to="/applyform">
              <Button className='text-white' variant="primary">Apply Here</Button>
            </Link>
          </Col>
  </Row>*/}
        <div className='text-left' style={{ height: '100px', margin: 'auto', }}>
          <h4 className='font-weight-bold'>Currently No position available will update soon...</h4>
        </div>
      </Container>
    </>
  );
};

export default JobPage;
