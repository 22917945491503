import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';

// Images
// import {ReactComponent as StandardSeat} from "@assets/pictures/svgs/seat-standard.svg";
// import {ReactComponent as SelectedSeat} from "@assets/pictures/svgs/seat-standard-selected.svg";
// import { ReactComponent as UnavailableSeat} from "@assets/pictures/svgs/seat-standard-unavailable.svg";
import { ReactComponent as Seat } from '@assets/pictures/svgs/seat_new.svg';
import { useSelector } from 'react-redux';
import { sortSeatsString } from '../../../store/auth/authActions';
import { getSeatIconUrl } from '@src/helper';
import { currencyFormatter } from '@helper/currencyFormatter';
import useIsMobile from '@src/customHooks/useIsMobile';

const SeatPlanIcons = (props) => {
  const { t, classes } = props;

  const isMobile = useIsMobile();
  let [uniqSeatsIcons, setUniqSeatsIcons] = useState([]);
  let userSelection = useSelector((state) => state.movies.userSelection);
  let seats = useSelector((state) => state.seatLayout.seats_selected);
  const seatString = seats.map((seat) => seat.sl_seat_name);
  const seatLayout = useSelector((state) => state.seatLayout.seatLayout);
  const [seatStringWithLogo, setSeatStringWithLogo] = useState([]);

  useEffect(() => {
    if (seatLayout) {
      let filtered = seatLayout
        ?.flatMap((s) => s?.seats?.flatMap((x) => x))
        ?.filter((x) => !!x);

      setUniqSeatsIcons([
        ...new Map(filtered.map((item) => [item['srt_id'], item])).values(),
      ]);
    }
  }, [seatLayout]);
  // useEffect(() => {
  //   let str = [];
  //   let seatsWithLogo = [];
  //   Object.values(groupBy(seats, 'srt_id')).forEach((type) => {
  //     if (type && type.length) {
  //       let seats = [];

  //       type.forEach((s) => {
  //         seats.push(s?.sl_seat_name);
  //       });

  //       seats.sort(sortSeatsString);
  //       seats = seats.join(',');
  //       seatsWithLogo.push({
  //         seats: seats,
  //         icon: getSeatIconUrl(type[0]?.srt_online_image_url),
  //       });

  //       seats = `${type[0]?.srt_name}: ${seats}`;

  //       str.push(seats);
  //     }
  //   });

  //   setSeatString(str.join(' | '));
  //   setSeatStringWithLogo(seatsWithLogo);
  // }, [seats]);

  let normalIcon = getSeatIconUrl(
    uniqSeatsIcons?.filter((x) => x?.srt_id == 1)?.[0]?.srt_online_image_url ||
      uniqSeatsIcons?.filter((x) => x?.srt_id == 10)?.[0]?.srt_online_image_url ||
      uniqSeatsIcons?.[0]?.srt_online_image_url,
  );
  let selectedIcon = getSeatIconUrl(
    uniqSeatsIcons?.filter((x) => x?.srt_id == 1)?.[0]
      ?.srt_online_image_url_selected ||
      uniqSeatsIcons?.filter((x) => x?.srt_id == 10)?.[0]
        ?.srt_online_image_url_selected ||
      uniqSeatsIcons?.[0]?.srt_online_image_url_selected,
  );
  let disabledIcon = getSeatIconUrl(
    uniqSeatsIcons?.filter((x) => x?.srt_id == 1)?.[0]
      ?.srt_online_image_url_disabled ||
      uniqSeatsIcons?.filter((x) => x?.srt_id == 10)?.[0]
        ?.srt_online_image_url_disabled ||
      uniqSeatsIcons?.[0]?.srt_online_image_url_disabled,
  );

  return (
    <>
      <div
        className="seat_plan_wrapper seat_plan_type"
        style={{ margin: 'auto', width: isMobile ? '100%' : '70%' }}
      >
        <div className="seat_plan_type_info_mobile">
          <p></p>
        </div>
      </div>

      <div className={`seat_plan_icons ${classes}`}>
        {/* <article>
          {uniqSeatsIcons &&
            uniqSeatsIcons.length > 1 &&
            uniqSeatsIcons
              .filter((s) => s?.srt_id != 2)
              .filter((s) => s?.srt_id != 12)
              .filter((s) => s?.srt_id != 13)
            .map((icon, index) => {
              console.log(index);
              if (index = 1) {
                <p className="">
                  <figure className="">

                    <img
                      src={getSeatIconUrl(icon.srt_online_image_url)}
                      width="24px"
                      height="24px"
                    />
                  </figure>
                  <p>{t(icon.srt_name) || icon.srt_name}</p>
                </p>
              }
          })}
        </article> */}
      </div>

      <div className="seat_plan_type_info_mobile"></div>

      <div className={`seat_plan_icons ${classes}`}>
        <article>
          {uniqSeatsIcons &&
            uniqSeatsIcons.length > 1 &&
            uniqSeatsIcons
              .filter((s) => s?.srt_id != 2)
              .filter((s) => s?.srt_id != 12)
              .filter((s) => s?.srt_id != 13)
              .slice(1)
              .map((icon, i) => (
                <p className="">
                  <figure className="">
                    {/*<Seat/> */}
                    <img
                      src={getSeatIconUrl(icon.srt_online_image_url)}
                      width="24px"
                      height="24px"
                    />
                  </figure>
                  <p>{t(icon.srt_name) || icon.srt_name}</p>
                </p>
              ))}

          <p className="">
            <figure className="">
              {/*<Seat/>*/}
              <img src={normalIcon} width="24px" height="24px" />
            </figure>
            <p>{t('Available')}</p>
          </p>

          <p className="seat_plan_icons_selected">
            <figure>
              {/*<Seat/>*/}
              <img src={selectedIcon} width="24px" height="24px" />
            </figure>
            <p>
              {seats.length > 0 ? t('Selected') : t('Pick Seats')}{' '}
              {seats.length > 0 && `(${seats?.length})`}
            </p>
          </p>

          <p className="seat_plan_icons_unavailable">
            <figure>
              {/*<Seat/>*/}
              <img src={disabledIcon} width="24px" height="24px" />
            </figure>
            {/* <p>{t('Unavailable')}</p> */}
            <p>{'Booked'}</p>
          </p>
        </article>
        {userSelection?.selectedScreeningType?.booking_type_id != 3 &&
          seats &&
          seats.length > 0 &&
          false && (
            <p>
              (
              {seatString.sort(sortSeatsString).map((seat, index) => (
                <span key={index}>{seat}</span>
              ))}
              )
            </p>
          )}
        {userSelection?.selectedScreeningType?.booking_type_id == 3 && (
          <p>
            (<span>{t('All')}</span>)
          </p>
        )}
      </div>
      <div style={{ display: 'none' }}>
        <article>
          <h6>
            <span>2.</span>{' '}
            {seats.length > 0 ? t('Selected Seats') : t('Pick Seats')}
          </h6>
          {seats.length > 0 && seatString && (
            <h6 className="text-success">
              {/*{seatString?.sort(sortSeatsString).map((seat, index, arr) => (
                            <React.Fragment key={index}>
                              {seat} {index !== arr.length - 1 ? ',' : ''}
                            </React.Fragment>
                          ))}*/}
              {/*{seatString}{' '}{seats.length > 0 && false && `(${seats?.length})`}*/}
              {seatStringWithLogo?.map((s, index, arr) => (
                <React.Fragment key={index}>
                  <img loading="lazy" width="24px" height="24px" src={s.icon} />
                  {'  '}: {s.seat}
                  {index !== arr.length - 1 ? ', ' : ' '}
                </React.Fragment>
              ))}
            </h6>
          )}
          {/*{seats.length > 0 && (<span>({t('Edit')})</span>)}*/}
        </article>
        <p>
          (
          {seatString.sort(sortSeatsString).map((seat, index) => (
            <span key={index}>{seat}</span>
          ))}
          )
        </p>
      </div>
    </>
  );
};

// Default Props
SeatPlanIcons.defaultProps = {
  classes: '',
};

export default withNamespaces()(SeatPlanIcons);
