// import React from 'react';
import { withNamespaces } from 'react-i18next';

import { Row, Container, Col, Dropdown, Modal, Alert } from 'react-bootstrap';
// import YouTubeImage from '@assets/pictures/youtube_image.png';
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
// import useIsMobile from '../../customHooks/useIsMobile';
import { useHistory, useLocation } from 'react-router-dom';
import useIsMobile from '@src/customHooks/useIsMobile';
import moment from 'moment';
import { getLangSpecificAttribute } from '@helper/languages';
import { useSelector, useDispatch } from 'react-redux';

// Components

import Slider from 'react-slick';
import seatsImg from '@assets/pictures/dolby_main.jpeg';
// import seatsImg1 from '@assets/pictures/untitled-22.jpg';
import seatsImg1 from '@assets/pictures/platinum suites2.jpeg';
import seatsImg2 from '@assets/pictures/untitled-41.jpg';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

import TrailerIcon from '@assets/pictures/svgs/play_new_exp.svg';
import ModalVideo from 'react-modal-video';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';

const Experience = ({ t }) => {
  const { t1, banners, isLoading, showOnly = null } = t;
  const isMobile = useIsMobile();
  const history = useHistory();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;
  const { pathname } = useLocation();
  const isAdvance = useSelector((state) => state.movies.isAdvance);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="align_new">
        <div className="left_right_align">
          {/* <BreadCrumb
            firstHalf={`${t('More')} >`}
            // secondHalf={`${t('Experience')}`}
            secondHalf={`${('Experience')}`}
          /> */}
          <CenteredTitle
            //   firstHalf={`${t('Privacy Policy')}`}
            firstHalf={`${'Experience'}`}
            secondHalf={''}
          />
        </div>
        {/* <ModalVideo
          autoplay
          channel={videoChannel}
          isOpen={isOpen}
          videoId={currentVideoId}
          onClose={() => setOpen(false)}
        /> */}
        {/* <Modal show={show} onHide={handleClose} className="modal_video_wrapper">
          <div>
            <iframe
              width="500"
              height="350"
              src="https://www.youtube.com/embed/HZS5a3cm7zU"
            ></iframe>
          </div>
        </Modal> */}
        <section className="container-fluid opening_hours">
          <Row>
            <Col xl={4} sm={12} md={4}>
              <div className="d-block parent_poster_wrapper">
                {/* {isAdvance && (
                  <div className="advance_tag">
                    <span>Advance</span>
                  </div>
                )} */}
                <p className="question_title">Dolby</p>
                <div
                  className={`${'movie_card_new custom_border_radius'} `}
                  // onMouseEnter={() => setIsShown(true)}
                  // onMouseLeave={() => setIsShown(false)}
                >
                  {/*has-tooltip previous class for on hover button*/}
                  {/* Image */}

                  <figure className="experience_img">
                    <img
                      src={seatsImg}
                      className="img-fluid "
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />

                    {/* <div class="details">
                      <img
                        src={TrailerIcon}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleShow();
                          // setVideoChannel('youtube');
                          // setCurrentVideoId('v=91');

                          // if (trailer?.mt_link?.includes('vimeo')) {
                          //   setVideoChannel('vimeo');
                          //   setCurrentVideoId(
                          //     trailer?.mt_link?.split('/').pop().split('.')?.[0],
                          //   );
                          // }
                          // setOpen(true);
                        }}
                      />

                      // <button className="book_now_btn">
                      //  {!isUpcoming ? 'Book Now' : 'More'}
                     // </button> 
                    </div> */}
                  </figure>

                  {/*Tool tip on hover */}
                  {/*{
          !!isUpcoming && (
          <p className="tooltip-new">

       <button className="btn_hov">
         {props?.mrrdr_release_date && t('From')} <br/>
            {props?.mrrdr_release_date && moment(props?.mrrdr_release_date).format('DD MMM YYYY')}
            </button>
          </p>
          )}*/}

                  <div className="experince_poster_details custon_z_index">
                    <div
                      className={`d-flex justify-content-between ${
                        isMobile ? 'mb-2' : ''
                      }`}
                    >
                      <div className="responsive_font_movie_title">
                        Enter a new world of sound with Dolby Atmos®. A leap forward
                        from surround sound, it transports you into the story with
                        moving audio that fills the cinema and flows all around you
                        to deliver the premier sound of entertainment.{' '}
                      </div>
                    </div>
                    <div className="explore_wrapper mt-2">
                      <button
                        className="explore_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push('/dolby');
                        }}
                      >
                        Explore
                      </button>
                    </div>
                  </div>
                </div>
                {/* {!isUpcoming && !now_demand_count > 0 && (
          <div className="parent_up_btn">
            <button className="up_btn" onClick={onClick}>
              {t('Book Now')}
            </button>
          </div>
        )} */}
              </div>
            </Col>

            <Col xl={4} sm={12} md={4}>
              <div
                className="d-block parent_poster_wrapper"
                style={{ marginTop: isMobile ? '22px' : '' }}
              >
                <p className="question_title">Platinum Suites </p>
                <div
                  className={`${'movie_card_new custom_border_radius'} `}
                  // onMouseEnter={() => setIsShown(true)}
                  // onMouseLeave={() => setIsShown(false)}
                >
                  {/*has-tooltip previous class for on hover button*/}
                  {/* Image */}

                  <figure className="experience_img">
                    <img
                      src={seatsImg1}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />

                    {/* <div class="details">
                      <img
                        src={TrailerIcon}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleShow();
                          // setVideoChannel('youtube');
                          // setCurrentVideoId('v=91');

                          // if (trailer?.mt_link?.includes('vimeo')) {
                          //   setVideoChannel('vimeo');
                          //   setCurrentVideoId(
                          //     trailer?.mt_link?.split('/').pop().split('.')?.[0],
                          //   );
                          // }
                          // setOpen(true);
                        }}
                      />

                      // <button className="book_now_btn">
                       // {!isUpcoming ? 'Book Now' : 'More'}
                      //</button> 
                    </div> */}
                  </figure>

                  {/*Tool tip on hover */}
                  {/*{
          !!isUpcoming && (
          <p className="tooltip-new">

       <button className="btn_hov">
         {props?.mrrdr_release_date && t('From')} <br/>
            {props?.mrrdr_release_date && moment(props?.mrrdr_release_date).format('DD MMM YYYY')}
            </button>
          </p>
          )}*/}

                  {/* Label */}

                  <div className="experince_poster_details custon_z_index">
                    <div
                      className={`d-flex justify-content-between ${
                        isMobile ? 'mb-2' : ''
                      }`}
                    >
                      <div className="responsive_font_movie_title">
                        You’ll feel like you’re inside the action as the sounds of
                        people, places, things, and music come alive with
                        breathtaking realism and move throughout the space.{' '}
                      </div>
                    </div>
                    <div className="explore_wrapper mt-2">
                      <button
                        className="explore_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push('/VipExp');
                        }}
                      >
                        Explore
                      </button>
                    </div>
                  </div>
                </div>
                {/* {!isUpcoming && !now_demand_count > 0 && (
          <div className="parent_up_btn">
            <button className="up_btn" onClick={onClick}>
              {t('Book Now')}
            </button>
          </div>
        )} */}
              </div>
            </Col>

            <Col xl={4} sm={12} md={4}>
              <div
                className="d-block parent_poster_wrapper"
                style={{ marginTop: isMobile ? '22px' : '' }}
              >
                {/* {isAdvance && (
                  <div className="advance_tag">
                    <span>Advance</span>
                  </div>
                )} */}
                <p className="question_title">KIDS</p>
                <div
                  className={`${'movie_card_new custom_border_radius'} `}
                  // onMouseEnter={() => setIsShown(true)}
                  // onMouseLeave={() => setIsShown(false)}
                >
                  {/*has-tooltip previous class for on hover button*/}
                  {/* Image */}

                  <figure className="experience_img">
                    <img
                      src={seatsImg2}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />

                    {/* <div class="details">
                      <img
                        src={TrailerIcon}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleShow();
                          // setVideoChannel('youtube');
                          // setCurrentVideoId('v=91');

                          // if (trailer?.mt_link?.includes('vimeo')) {
                          //   setVideoChannel('vimeo');
                          //   setCurrentVideoId(
                          //     trailer?.mt_link?.split('/').pop().split('.')?.[0],
                          //   );
                          // }
                          // setOpen(true);
                        }}
                      />

                      // <button className="book_now_btn">
                      //  {!isUpcoming ? 'Book Now' : 'More'}
//</button> 
                    </div> */}
                  </figure>

                  {/*Tool tip on hover */}
                  {/*{
          !!isUpcoming && (
          <p className="tooltip-new">

       <button className="btn_hov">
         {props?.mrrdr_release_date && t('From')} <br/>
            {props?.mrrdr_release_date && moment(props?.mrrdr_release_date).format('DD MMM YYYY')}
            </button>
          </p>
          )}*/}

                  {/* Label */}

                  <div className="experince_poster_details custon_z_index">
                    <div
                      className={`d-flex justify-content-between ${
                        isMobile ? 'mb-2' : ''
                      }`}
                    >
                      <div className="responsive_font_movie_title">
                        A luxurious experience only for moviegoers who Love to enjoy
                        a unique experience and warm space, with special service and
                        five-star food{' '}
                      </div>
                    </div>
                    <div className="explore_wrapper mt-2">
                      <button
                        className="explore_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push('/Kids');
                        }}
                      >
                        Explore
                      </button>
                    </div>
                  </div>
                </div>
                {/* {!isUpcoming && !now_demand_count > 0 && (
          <div className="parent_up_btn">
            <button className="up_btn" onClick={onClick}>
              {t('Book Now')}
            </button>
          </div>
        )} */}
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
};
export default withNamespaces()(Experience);
