import MovieService from '@apiService/MovieService';
import GenericService from '@apiService/GenericService';
import {
  FETCH_LABELED_MOVIES,
  FETCH_LABELED_ONDEMAND_MOVIES,
  FETCH_FORMAT_LIST,
  FETCH_FORMAT_LIST_SUCCESS,
  FETCH_FORMAT_LIST_ERROR,
  FETCH_LANGUAGE_LIST,
  FETCH_LANGUAGE_LIST_SUCCESS,
  FETCH_LANGUAGE_LIST_ERROR,
  SEARCH_MOVIE,
  SEARCH_MOVIE_SUCCESS,
  SEARCH_MOVIE_ERROR,
  QUICK_BOOK,
  QUICK_BOOK_MOVIE,
  QUICK_BOOK_MOVIE_ERROR,
  QUICK_BOOK_MOVIE_SUCCESS,
  QUICK_BOOK_LANG_SUCCESS,
  QUICK_BOOK_FORMAT_SUCCESS,
  QUICK_BOOK_CINEMA_SUCCESS,
  QUICK_BOOK_DATE_SUCCESS,
  QUICK_BOOK_TIME_SUCCESS,
  QUICK_BOOK_ERROR,
  FETCH_FILTERS,
  FETCH_FILTERS_SUCCESS,
  FETCH_FILTERS_ERROR,
  SET_FILTER,
  SET_QB_STAGE,
  SET_SELECTED_MINI_FESTIVAL,
  FETCH_CMS,
  FETCH_CMS_SUCCESS,
  FETCH_CMS_ERROR,
  SET_ON_DEMAND_CMS,
  SET_SOCIALMEDIA_CMS,
  SET_CMS_SETTINGS,
  SET_HEADERS_CMS,
  SET_FOOTER_CMS,
  SET_MOVIE_CMS,
} from './homepageTypes';

export const fetchFormatList = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: FETCH_FORMAT_LIST });
    // const { data } = await MovieService.GetFormatList(payload);
    // if (data.status && data.Records.data.length >= 0) {
    //   dispatch({
    //     type: FETCH_FORMAT_LIST_SUCCESS,
    //     payload: data.Records.data,
    //   });
    // } else {
    //   dispatch({ type: FETCH_FORMAT_LIST_ERROR, payload: data.message });
    // }
  } catch (error) {
    dispatch({ type: FETCH_FORMAT_LIST_ERROR, payload: error?.message });
  }
};

export const fetchLanguageList = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: FETCH_LANGUAGE_LIST });
    // const { data } = await MovieService.GetLanguageList(payload);
    // if (data.status && data.Records.data.length >= 0) {
    //   dispatch({
    //     type: FETCH_LANGUAGE_LIST_SUCCESS,
    //     payload: data.Records.data,
    //   });
    // } else {
    //   dispatch({ type: FETCH_LANGUAGE_LIST_ERROR, payload: data.message });
    // }
  } catch (error) {
    dispatch({ type: FETCH_LANGUAGE_LIST_ERROR, payload: error?.message });
  }
};

export const quickBookMovieList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: QUICK_BOOK_MOVIE });
    const { data } = await MovieService.QuickBookMovieList(payload);
    if (data.status && data.Records.data.length > 0) {
      dispatch({
        type: QUICK_BOOK_MOVIE_SUCCESS,
        payload: data.Records.data,
      });
      // if (payload?.type === `quickBook`) {
      //   dispatch({
      //     type: QUICK_BOOK_MOVIE_SUCCESS,
      //     payload: data.Records.data,
      //   });
      // }
    } else {
      dispatch({ type: QUICK_BOOK_MOVIE_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: QUICK_BOOK_MOVIE_ERROR, payload: error?.message });
  }
};

export const setSearchMovies = (payload) => ({
  type: SEARCH_MOVIE_SUCCESS,
  payload,
});

export const searchMovie = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_MOVIE });
    const { data } = await MovieService.SearchMovie(payload);
    if (data.status && data.Records.data.length > 0) {
      dispatch(setSearchMovies(data.Records.data));
      if (payload?.type === `quickBook`) {
        dispatch({
          type: QUICK_BOOK_MOVIE_SUCCESS,
          payload: data.Records.data,
        });
      }
    } else {
      dispatch({ type: SEARCH_MOVIE_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: SEARCH_MOVIE_ERROR, payload: error?.message });
  }
};

export const quickBook =
  ({ movie_id, lang_id, mf_id, cinema_id, fromDate }) =>
  async (dispatch, getState) => {
    // dispatch({ type: QUICK_BOOK });
    try {
      const { data } = await MovieService.QuickBook({
        movie_id,
        lang_id,
        mf_id,
        cinema_id,
        fromDate,
      });
      if (data.status) {
        const { qbStage } = getState().homepage;
        if (movie_id && qbStage === 1) {
          dispatch({ type: QUICK_BOOK_LANG_SUCCESS, payload: data.Records.data });
        }
        if (lang_id && qbStage === 2) {
          dispatch({
            type: QUICK_BOOK_FORMAT_SUCCESS,
            payload: data.Records.data,
          });
        }
        if (mf_id && qbStage === 3) {
          dispatch({
            type: QUICK_BOOK_CINEMA_SUCCESS,
            payload: data.Records.data,
          });
        }
        if (cinema_id && qbStage === 4) {
          dispatch({ type: QUICK_BOOK_DATE_SUCCESS, payload: data.Records.data });
        }
        if (fromDate && qbStage === 5) {
          dispatch({ type: QUICK_BOOK_TIME_SUCCESS, payload: data.Records.data });
        }
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: QUICK_BOOK_ERROR, payload: error?.message });
    }
  };

export const fetchFilters = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_FILTERS });
    const { data } = await MovieService.GetFilters(payload);
    if (data.status && data.filterData.length > 0) {
      dispatch({
        type: FETCH_FILTERS_SUCCESS,
        payload: data.filterData,
      });
    } else {
      dispatch({ type: FETCH_FILTERS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_FILTERS_ERROR, payload: error?.message });
  }
};

export const setFilter = (filter) => async (dispatch) => {
  try {
    dispatch({ type: SET_FILTER, payload: filter });
  } catch (error) {
    console.error(error);
  }
};

export const setQbStage = (payload) => async (dispatch) =>
  dispatch({ type: SET_QB_STAGE, payload });

export const setSelectedMiniFestival = (festival) => async (dispatch) =>
  dispatch({ type: SET_SELECTED_MINI_FESTIVAL, payload: festival });

export const fetchSectionCMS = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CMS });
    const { data } = await GenericService.GetSectionCMS(payload);
    // const data = {
    //   status: true,
    //   message: 'Fetched SuccessFully',
    //   Records: [
    //     {
    //       cms_id: 1,
    //       cinema_id: null,
    //       section_id: 1,
    //       type_name: 'On Demand',
    //       data: {
    //         type: 1,
    //         type_name: 'On Demand',
    //         keydata: [
    //           {
    //             lang_id: 1,
    //             lang_name: 'English',
    //             alternate_lang_name: null,
    //             lang_is_active: 'Y',
    //             iso_2: 'EN',
    //             iso_3: 'ENG',
    //             order: 1,
    //             langauge: 'English',
    //             langaugeId: 1,
    //             key: [
    //               {
    //                 type: 'file',
    //                 key_name: 'image_url',
    //                 label: 'On Demand Header Image',
    //               },
    //               {
    //                 type: 'textarea',
    //                 key_name: 'sub_header',
    //                 label: 'On Demand Sub header',
    //               },
    //             ],
    //             image_url: null,
    //             sub_header: '',
    //           },
    //         ],
    //         isCollapsed: true,
    //         islanguageAvailable: true,
    //         isMultipleBlock: false,
    //         key: [
    //           {
    //             type: 'file',
    //             key_name: 'image_url',
    //             label: 'On Demand Header Image',
    //           },
    //           {
    //             type: 'textarea',
    //             key_name: 'sub_header',
    //             label: 'On Demand Sub header',
    //           },
    //         ],
    //         cms_id: 1,
    //       },
    //       is_cms_active: 'Y',
    //     },
    //     {
    //       cms_id: 2,
    //       cinema_id: null,
    //       section_id: 3,
    //       type_name: 'Home Page',
    //       data: {
    //         type: 3,
    //         type_name: 'Home Page',
    //         keydata: [
    //           {
    //             show_on_demand: 'Y',
    //             show_upcoming: 'Y',
    //             show_mini_festivals: 'Y',
    //             show_quick_book: 'Y',
    //             show_filters: 'Y',
    //           },
    //         ],
    //         isCollapsed: false,
    //         islanguageAvailable: false,
    //         isMultipleBlock: false,
    //         key: [
    //           {
    //             type: 'radio',
    //             key_name: 'show_on_demand',
    //             label: 'Show On Demand',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'show_upcoming',
    //             label: 'Show Upcoming',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'show_mini_festivals',
    //             label: 'Show Mini Festivals',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'show_quick_book',
    //             label: 'Quick Book (On Demand)',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'show_filters',
    //             label: 'Show Filters',
    //           },
    //         ],
    //         cms_id: 2,
    //       },
    //       is_cms_active: 'Y',
    //     },
    //     {
    //       cms_id: 3,
    //       cinema_id: null,
    //       section_id: 2,
    //       type_name: 'Social Media',
    //       data: {
    //         type: 2,
    //         type_name: 'Social Media',
    //         keydata: [
    //           {
    //             facebok_link: '',
    //             twitter_link: '',
    //             insta_link: '',
    //             youtube_link: '',
    //           },
    //         ],
    //         isCollapsed: false,
    //         islanguageAvailable: false,
    //         isMultipleBlock: false,
    //         key: [
    //           {
    //             type: 'text',
    //             key_name: 'facebok_link',
    //             label: 'Facebook',
    //           },
    //           {
    //             type: 'text',
    //             key_name: 'twitter_link',
    //             label: 'Twitter',
    //           },
    //           {
    //             type: 'text',
    //             key_name: 'insta_link',
    //             label: 'Instagram',
    //           },
    //           {
    //             type: 'text',
    //             key_name: 'youtube_link',
    //             label: 'Youtube',
    //           },
    //         ],
    //         cms_id: 3,
    //       },
    //       is_cms_active: 'Y',
    //     },
    //     {
    //       cms_id: 4,
    //       cinema_id: null,
    //       section_id: 4,
    //       type_name: 'Menu',
    //       data: {
    //         type: 4,
    //         type_name: 'Menu',
    //         keydata: [
    //           {
    //             Cinemas: 'Y',
    //             'Book An Event': 'Y',
    //             'Mini Festivals': 'N',
    //             Schedules: 'N',
    //             'On Demand': 'Y',
    //             Languages: 'Y',
    //             'Show Selected Cinema': 'Y',
    //           },
    //         ],
    //         isCollapsed: true,
    //         islanguageAvailable: false,
    //         isMultipleBlock: false,
    //         key: [
    //           {
    //             type: 'radio',
    //             key_name: 'Cinemas',
    //             label: 'Cinemas',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Book An Event',
    //             label: 'Book An Event',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Mini Festivals',
    //             label: 'Mini Festivals',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Schedules',
    //             label: 'Schedules',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'On Demand',
    //             label: 'On Demand',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Languages',
    //             label: 'Languages',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Show Selected Cinema',
    //             label: 'Show Selected Cinema',
    //           },
    //         ],
    //         cms_id: 4,
    //       },
    //       is_cms_active: 'Y',
    //     },
    //     {
    //       cms_id: 5,
    //       cinema_id: null,
    //       section_id: 5,
    //       type_name: 'Footer Menu',
    //       data: {
    //         type: 5,
    //         type_name: 'Footer Menu',
    //         keydata: [
    //           {
    //             Logo: 'Y',
    //             NewsLetter: 'Y',
    //             'Social Links': 'Y',
    //             'How its Work': 'Y',
    //             Cinemas: 'Y',
    //             'Promotion n Offers': 'Y',
    //             'About US': 'Y',
    //             'Contact US': 'Y',
    //             FAQs: 'Y',
    //             Documentation: 'Y',
    //             'Terms n Conditions': 'Y',
    //             'Privacy Policy': 'Y',
    //           },
    //         ],
    //         isCollapsed: true,
    //         islanguageAvailable: false,
    //         isMultipleBlock: false,
    //         key: [
    //           {
    //             type: 'radio',
    //             key_name: 'Logo',
    //             label: 'Logo',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'NewsLetter',
    //             label: 'News Letter',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Social Links',
    //             label: 'Social Media',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'How its Work',
    //             label: 'How its Work',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Cinemas',
    //             label: 'Cinema Listing',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Promotion n Offers',
    //             label: 'Promotion n Offers',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'About US',
    //             label: 'About US',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Contact US',
    //             label: 'Contact US',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'FAQs',
    //             label: 'FAQs',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Documentation',
    //             label: 'Documentation',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Terms n Conditions',
    //             label: 'Terms n Conditions',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'Privacy Policy',
    //             label: 'Privacy Policy',
    //           },
    //         ],
    //         cms_id: 5,
    //       },
    //       is_cms_active: 'Y',
    //     },
    //     {
    //       cms_id: 6,
    //       cinema_id: null,
    //       section_id: 6,
    //       type_name: 'Movie Related CMS',
    //       data: {
    //         type: 6,
    //         type_name: 'Movie Related CMS',
    //         keydata: [
    //           {
    //             show_feature_version: 'Y',
    //             show_filters: 'Y',
    //           },
    //         ],
    //         isCollapsed: false,
    //         islanguageAvailable: false,
    //         isMultipleBlock: false,
    //         key: [
    //           {
    //             type: 'radio',
    //             key_name: 'show_feature_version',
    //             label: 'Show Feature Versions',
    //           },
    //           {
    //             type: 'radio',
    //             key_name: 'show_filters',
    //             label: 'Show Movie Filters',
    //           },
    //         ],
    //         cms_id: 6,
    //       },
    //       is_cms_active: 'Y',
    //     },
    //   ],
    // };

    if (data.status && data.Records.length > 0) {
      // console.log("data fetchSectionCMS", data.Records);
      data.Records.map((x) => {
        if (x.section_id == 1) {
          dispatch({
            type: SET_ON_DEMAND_CMS,
            payload: x.data.keydata,
          });
        } else if (x.section_id == 2) {
          dispatch({
            type: SET_SOCIALMEDIA_CMS,
            payload: x.data.keydata,
          });
        } else if (x.section_id == 3) {
          dispatch({
            type: SET_CMS_SETTINGS,
            payload: x.data.keydata,
          });
        } else if (x.section_id == 4) {
          dispatch({
            type: SET_HEADERS_CMS,
            payload: x.data.keydata,
          });
        } else if (x.section_id == 5) {
          dispatch({
            type: SET_FOOTER_CMS,
            payload: x.data.keydata,
          });
        } else if (x.section_id == 6) {
          dispatch({
            type: SET_MOVIE_CMS,
            payload: x.data.keydata,
          });
        }
      });
      dispatch({
        type: FETCH_CMS_SUCCESS,
        payload: data.Records,
      });
    } else {
      dispatch({ type: FETCH_CMS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_CMS_ERROR, payload: error?.message });
  }
};
