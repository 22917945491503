import React, { useEffect, useState, useRef, useContext } from 'react';
import { withNamespaces } from 'react-i18next';
import { Row, Container, Col, Dropdown, Modal, Alert } from 'react-bootstrap';
import cinemaBg from '@assets/pictures/cinema-banner.png';
import cinema from '@assets/pictures/cinema.png';
import whiteArrowDown from '@assets/pictures/svgs/white-arrow-down.svg';
import map from '@assets/pictures/svgs/map.svg';
import Slider from 'react-slick';
import AuditoriumCard from './partials/AuditoriumCard.jsx';
import AncilliaryHeroSection from './partials/AncilliaryHeroSection.jsx';
import { fetchCinemas } from '../store/movies/movieActions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fetchAllCinemas, selectedCinema } from '@store/cinemas/cinemasActions';
import Spinner from './partials/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import Nav from 'react-bootstrap/Nav';
import CinemaLocationIcon from '@assets/pictures/svgs/Cinema_location.svg';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
const CinemaListing = ({ t }) => {
  const dispatch = useDispatch();
  const cinemas = useSelector((state) => state.cinemas);
  const { cinemasLoading, allCinemas } = cinemas;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  // const heroSlider = {
  //   arrows: false,
  //   dots: false,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   speed: 500,
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  //   responsive: [
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         arrows: false,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  const history = useHistory();
  // const [cinemaDetails, setCinemaDetails] = useState(false);
  // if (cinemaDetails === true) {
  //   history.push("/cinema-details");
  // }
  // useEffect(() => {
  //   if (!cinemas?.allCinemas) {
  //     dispatch(fetchAllCinemas());
  //   }
  //   window.scrollTo(0, 0);
  // }, []);
  useEffect(() => {
    dispatch(fetchAllCinemas());
  }, [dispatch]);
  useEffect(() => window.scrollTo(0, 0), []);
  const heroSectionObj = {
    arr: [1, 2],
    backgroundImg: cinemaBg,
    title: t('cinema_listing.maintitle'),
    msg: t('cinema_listing.maindesc'),
  };
  const [key, setKey] = useState('home');
  return cinemasLoading ? (
    <section className="container-fluid">
      <div className="row my-5">
        <div className="col-12 text-center">
          <Spinner />
        </div>
      </div>
    </section>
  ) : allCinemas?.length > 0 ? (
    <div>
      <div className="container-fluid align_new">
        {/* <BreadCrumb firstHalf={`${t('Home')} >`} secondHalf={t('Location')} /> */}
        <CenteredTitle firstHalf={t('Locations')} secondHalf={''} />
      </div>
      <div className="px-0">
        {/* Hero Section */}
        {/* <Slider {...heroSlider} className="">
          {[1, 2].map((banner, index) => (
            <section className="cinema-hero-section" key={index}>
              <div className="hero-img-section">
                <img src={cinemaBg} alt="Movie Banner" className="hero-img-1" />
              </div>
              <div className="hero-text justify-content-center">
                <div className="container-fluid container-xl">
                  <div className="row">
                    <div className="col-10 col-sm-8 col-lg-6 mx-auto text-center">
                      <p className="title">Cinemas</p>
                      <p className="msg">
                        We have the biggest cinema loyalty scheme, we - offering
                        guests the chance to see all the films they want, as
                        often as they like.
                      </p>
                      <div className="d-flex justify-content-center">
                        <img src={whiteArrowDown} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))}
        </Slider> */}
        {/* <AncilliaryHeroSection sectionObj={heroSectionObj} /> */}

        <section className="container-fluid container-xl mt-3 location_wrapper">
          <div className="">
            {/* <!---New Design---> */}
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={1}
              className="custom_nav_link"
            >
              <Row>
                <Col sm={12} lg={12} xl={12} className="slider_section">
                  <Nav variant="pills" className="custom_overflow_location">
                    {allCinemas?.map((cinema, index) => (
                      <Nav.Item>
                        <Nav.Link eventKey={cinema.cinema_id}>
                          <div className="d-block location_cinema_card">
                            <img
                              src={cinema.cine_image_url}
                              className="img_location"
                            />

                            <h5>{cinema.cinema_name}</h5>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Col>
                <Col sm={12} lg={12} xl={12} className="mt-3">
                  <Tab.Content>
                    {allCinemas?.map((cinemaData, index) => (
                      <Tab.Pane eventKey={cinemaData?.cinema_id}>
                        <Row className="m-auto">
                          <Col
                            sm={12}
                            lg={12}
                            xl={3}
                            className="d-flex flex-column align-items-center justify-content-center text-center text-white"
                            style={{ backgroundColor: 'var(--blue)' }}
                          >
                            <div>
                              <img
                                src={CinemaLocationIcon}
                                className="location_icon_img"
                              />
                            </div>
                            <div className="cinema_details_wrapper">
                              <p>{cinemaData?.cinema_name}</p>
                              <p className="my-2">{cinemaData?.cinema_address}</p>
                              <p
                                style={{
                                  marginBottom: isMobile || isTablet ? '10px' : '',
                                }}
                                className="mb-2"
                              >
                                <a
                                  href={`mailto:${cinemaData?.cinema_email}`}
                                  style={{
                                    color: 'var(--star-yellow)',
                                  }}
                                >
                                  {cinemaData?.cinema_email}
                                </a>
                              </p>
                              <p className="mb-2">
                                +
                                {cinemaData?.cine_mobile_code +
                                  ' ' +
                                  cinemaData?.cinema_phone_number}
                              </p>
                            </div>
                          </Col>

                          <Col
                            sm={12}
                            lg={12}
                            xl={9}
                            className={`${isMobile || isTablet ? 'px-0' : 'pl-0'}`}
                          >
                            <div
                              className="iframe_wrapper"
                              style={{ height: '450px' }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  cinemaData?.google_map_embedded_url.replaceAll(
                                    '&lt;',
                                    '<',
                                  ),
                              }}
                            />
                          </Col>
                        </Row>

                        {/* <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : isTablet ? '450' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas"
                        src={cinemaData.ma}
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe> */}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </section>
      </div>
    </div>
  ) : null;
};

export default withNamespaces()(CinemaListing);
