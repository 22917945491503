import { withNamespaces } from 'react-i18next';
import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Banner from '@components/partials/Banner';
import MoviesNFestsNew from '@components/partials/MoviesNFestsNew';
import { Dropdown, Modal, Spinner } from 'react-bootstrap';
import { useGetBannersQuery } from '@store/rtk/rtk.banner';
import { useGetUpcomingQuery } from '@store/rtk/rtk.movieLists';

// Images

const UpcomingShows = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organization = useSelector((state) => state.movies.organizationDetails);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;

  const { data: banners = [] } = useGetBannersQuery();
  const { data: comingSoonMovies, isLoading: comingSoonLoading } =
    useGetUpcomingQuery();

  const uniqueComingSoonMovies = {
    ...comingSoonMovies,
    data: [
      ...new Map(
        comingSoonMovies?.data?.map((item) => [item['movie_id'], item]),
      ).values(),
    ],
  };

  const upcomingBanner = banners.filter((x) => {
    if (x?.movie_id) {
      if (x?.movie_banner_type_id?.split(',')?.includes('3')) {
        return x;
      }
    } else {
      return x;
    }
  });

  return (
    <>
      <Banner banners={upcomingBanner} showOnly={3} />

      <div className="container-fluid">
        {/* Movie Sections */}
        {uniqueComingSoonMovies?.data?.length > 0 ? (
          <MoviesNFestsNew
            section={4}
            moviesArray={uniqueComingSoonMovies?.data}
            subCategory={''}
            category={t('Upcoming')}
            movieCount={uniqueComingSoonMovies?.data?.length}
            type="show_upcoming"
            slider
            showMovieList={{ category_id: 3 }}
          />
        ) : comingSoonLoading ? (
          <section className="row">
            <div className="col-12 text-center">
              <Spinner animation="border" role="status" className="mx-auto" />
            </div>
          </section>
        ) : (
          <section className="row">
            <div className="col-12 text-center">
              <h6 className="mx-auto mb-0">{t('common.No movies found')}</h6>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default withNamespaces()(UpcomingShows);
