import { withNamespaces } from 'react-i18next';
import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';

// Components
import Banner from '@components/partials/Banner';
import MoviesNFestsNew from '@components/partials/MoviesNFestsNew';
import OnDemandInfo from '@components/partials/OnDemandInfo';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLangSpecificAttribute } from '@helper/languages';
import { Dropdown, Modal, Spinner } from 'react-bootstrap';
import { useGetBannersQuery } from '@store/rtk/rtk.banner';
import {
  useGetMiniFestivalQuery,
  useGetOnDemandQuery,
} from '@store/rtk/rtk.movieLists';

// Images

const NewOnDemandContainer = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const organization = useSelector((state) => state.movies.organizationDetails);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;

  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser } = loginDetails;

  const { data: banners = [] } = useGetBannersQuery();
  const { data: createOwnShowMovies, isLoading: onDemandLoading } =
    useGetOnDemandQuery({
      limit: 1000,
      currentPage: 1,
      isUserLogged: loggedInUser ? 'Y' : 'N',
    });
  const { data: miniFestivalsData, isLoading: miniFestivalsLoading } =
    useGetMiniFestivalQuery();

  const uniqueCreateOwnShowMovies = {
    ...createOwnShowMovies,
    data: [
      ...new Map(
        createOwnShowMovies?.data?.map((item) => [item['movie_id'], item]),
      ).values(),
    ],
  };

  const onDemandBanner = banners.filter((x) => {
    if (x?.movie_id) {
      // if (x?.movie_banner_type_id?.split(',')?.includes('2')) {
      //   return x;
      // }

      return x?.now_demand_count > 0 && x?.now_showing_count == 0;
    } else {
      return x;
    }
  });

  const noResultFound = () => {
    let status = false;
    let count = 0;
    let temp = miniFestivalsData?.Records?.map((x) => {
      if (x?.movie_data?.data.length > 0) {
        count += 1;
      }
    });

    if (count == 0 && uniqueCreateOwnShowMovies?.data?.length == 0) {
      status = true;
    } else {
      status = false;
    }
    return status;
  };

  return (
    <>
      <Banner banners={onDemandBanner} showOnly={2} />

      <div className="container-fluid">
        {/* Movie Sections */}
        {miniFestivalsData?.Records?.length > 0 &&
          miniFestivalsData?.Records?.map(
            (category, index) =>
              category?.movie_data?.data?.length > 0 && (
                <MoviesNFestsNew
                  key={index}
                  section={2}
                  moviesArray={category?.movie_data?.data}
                  subCategory={
                    getLangSpecificAttribute(
                      category?.language_content,
                      lang_id,
                      'event_name',
                      'lang_id',
                    ) || category?.category_link_name
                  }
                  category={''}
                  type="on_demand"
                  movieCount={category?.movie_data?.data?.length}
                  slider
                  showMovieList={{
                    category_id: 4,
                    section_id: category?.unique_id,
                  }}
                />
              ),
          )}

        {uniqueCreateOwnShowMovies?.data?.length > 0 && (
          <MoviesNFestsNew
            section={2}
            type="on_demand"
            moviesArray={uniqueCreateOwnShowMovies?.data}
            subCategory={'All'}
            category={''}
            movieCount={uniqueCreateOwnShowMovies?.data?.length}
            slider
            showMovieList={{ category_id: 2 }}
          />
        )}

        <section className="row">
          <div className="col-12 text-center">
            {onDemandLoading || miniFestivalsLoading ? (
              <Spinner animation="border" role="status" className="mx-auto" />
            ) : (
              noResultFound() && (
                <h6 className="mx-auto mb-0">{t('common.No movies found')}</h6>
              )
            )}
          </div>
        </section>

        {/* <OnDemandInfo/> */}
      </div>
    </>
  );
};

export default withNamespaces()(NewOnDemandContainer);
