import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import back from '@assets/pictures/svgs/back-modal.svg';
import close from '@assets/pictures/svgs/close.svg';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from '@components/partials/Spinner';

import { BASEURL } from '@config/index';
const RatingPopup = (props) => {
  const {
    t,
    closeFunc,
    screen,
    format,
    onClickShowMobile,
    pp_title,
    pp_description,
    pp_banner,
    rating,
    rating_id,
  } = props;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();
  const { pathname } = useLocation();

  // const movieDetails = useSelector((state) => state.movies.movieDetails);
  // const [movieInfoObj, setMovieInfoObj] = useState({});

  // useEffect(() => {
  //   setMovieInfoObj({
  //     image: movieDetails?.pp_banner,

  //     pp_title: movieDetails?.pp_title,
  //     pp_description: movieDetails?.pp_description,
  //     rating: movieDetails?.rating,
  //     rating_id: movieDetails?.rating_id,
  //   });
  // }, [movieDetails]);

  let rating_description = pp_description?.replaceAll('&lt;', '<');

  return (
    <Modal
      show={rating_id !== 4 ?? false}
      aria-labelledby="contained-modal-title-vcenter"
      className="show-details-popup"
      centered
      onHide={() => closeFunc(false)}
    >
      <section className="wrapper container-fluid">
        {/* <img
          src={close}
          className="close-btn-main"
          onClick={() => closeFunc(false)}
          alt=""
        /> */}
        {/* {cities?.length === 0 && (
          <div className="row">
            <div className="col-12 text-center">
              <Spinner />
            </div>
          </div>
        )} */}
        <div className="row mt-4 align-items-center">
          <div className="col-3">
            <img src={BASEURL + pp_banner} className="rating_img" />
          </div>
          <div className="col-9" style={{ lineHeight: '23px' }}>
            {pp_title
              ? pp_title
              : `Parental Guidance is required for viewers under the age of ${rating}`}
          </div>
          <div
            className="col-12 mt-2 rating_descripton"
            dangerouslySetInnerHTML={{ __html: rating_description }}
          />
        </div>
        <div
          className="d-flex align-items-center justify-content-center mt-3"
          style={{ gap: '20px' }}
        >
          <button
            className="new_design"
            style={{ border: ' 1px solid var(--star-yellow)' }}
            onClick={
              // isTouch ? undefined :
              () => {
                closeFunc(true);
                console.log('ONCLICKPopup');
              }
            }
          >
            I Agree
          </button>
          <button className="cancel_btn" onClick={() => closeFunc(true)}>
            Cancel
          </button>
        </div>
      </section>
    </Modal>
  );
};

export default withNamespaces()(RatingPopup);
